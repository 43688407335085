import React, { useState } from "react"
import { Table } from "ant-table-extensions"
import { Button, Modal, Tag } from "antd"
import { useEffect } from "react"
import { getGroupsOverview } from "../../../../controllers/GroupManagementController"
import Checkbox from "antd/lib/checkbox/Checkbox"
import { CheckCircleOutlined, SearchOutlined } from "@ant-design/icons"

export default function SelectStudentGroups({
   selectedGroups,
   onSelectionFinished,
   ...prop
}) {
   const [show, setShow] = useState(false)
   const [groupOverview, setGroupOverview] = useState([])

   const columns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Group Name",
         dataIndex: "name",
         key: "groupName",
      },
      {
         title: "Select",
         dataIndex: "select",
         key: "select",
         render: (text, record) => {
            return (
               <Checkbox
                  checked={record.selected}
                  onChange={(v) => {
                     setGroupOverview(
                        groupOverview.map((g) => {
                           if (g.id === record.id) {
                              g.selected = v.target.checked
                           }
                           return g
                        })
                     )
                  }}
               />
            )
         },
      },
   ]

   useEffect(() => {
      const getGroupOverview = async () => {
         const groupOverview = await getGroupsOverview()

         if (groupOverview.isSuccess)
            setGroupOverview(
               groupOverview.data.map((group) => {
                  return {
                     ...group,
                     selected: selectedGroups.includes(group.id),
                  }
               })
            )
      }

      getGroupOverview()
   }, [])

   useEffect(() => {
      setGroupOverview(
         groupOverview.map((group) => {
            return {
               ...group,
               selected: selectedGroups.includes(group.id),
            }
         })
      )
   }, [selectedGroups])

   return (
      <>
         <Modal
            visible={show}
            onOk={() => {
               if (onSelectionFinished !== null)
                  onSelectionFinished(groupOverview.filter((x) => x.selected))

               setShow(false)
            }}
            title='Select Student Group'
            onCancel={() => setShow(false)}>
            {groupOverview.filter((g) => g.selected).length > 0 ? (
               <div className='flex-inline p-2  rounded-md mb-2'>
                  {groupOverview
                     .filter((x) => x.selected)
                     .map((g) => {
                        return (
                           <Tag
                              key={g.id}
                              closable={true}
                              onClose={() => {
                                 setGroupOverview(
                                    groupOverview.map((group) => {
                                       if (group.id === g.id) {
                                          return { ...group, selected: false }
                                       }
                                       return group
                                    })
                                 )
                              }}>
                              {g.name}
                           </Tag>
                        )
                     })}
               </div>
            ) : (
               <></>
            )}
            <Table
               searchableProps={{
                  // dataSource,
                  // setDataSource: setSearchDataSource,
                  inputProps: {
                     placeholder: "Search",
                     prefix: <SearchOutlined />,
                  },
               }}
               searchable={true}
               columns={columns}
               dataSource={groupOverview}></Table>
         </Modal>

         <Button
            type='primary'
            size='large'
            icon={<CheckCircleOutlined />}
            onClick={() => {
               setShow(true)
            }}>
            Select Groups
         </Button>
      </>
   )
}
