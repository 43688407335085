import { AntDesignOutlined } from "@ant-design/icons"
import {
   Col,
   Input,
   message,
   Row,
   Typography,
   Form,
   Button,
   Avatar,
   Tabs,
} from "antd"
import React from "react"
import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"
import { Container, Jumbotron } from "react-bootstrap"
import {
   getStudentProfile,
   updateStudentEmail,
   updateStudentPassword,
   updateStudentProfile,
   updateStudentProfilePicture,
} from "../../controllers/student/profileManagement"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../helpers/NotificationSystem"
import MainNavbar from "../MainNavbar"

const { Title } = Typography
const { TabPane } = Tabs

export default function ProfileManagement() {
   const [loading, setLoading] = useState(false)
   const [profile, setProfile] = useState({
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      profilePicture: "",
      id: 0,
      organization: "",
   })

   const profileForm = useRef()
   const passwordForm = useRef()
   const emailForm = useRef()

   useEffect(() => {
      const getProfile = async () => {
         const res = await getStudentProfile()
         if (res.isSuccess) {
            setProfile(res.data)
            profileForm.current?.setFieldsValue(res.data)
            passwordForm.current?.setFieldsValue(res.data)
            emailForm.current?.setFieldsValue(res.data)
         } else message.error(res.message)
      }

      getProfile()
   }, [])

   const showMessage = (title, type) => {
      const d = {
         content: title,
         style: {
            marginTop: "80px",
         },
         key: "1",
      }
      if (type === "success") message.success({ ...d })
      else if (type === "info") message.info({ ...d })
      else if (type === "error") message.error({ ...d })
      else if (type === "loading") message.loading({ ...d })
   }

   const updateProfilePicture = (e) => {
      var file = e.target.files[0]
      var reader = new FileReader()
      reader.onloadend = async function () {
         showMessage("Uploading profile picture...", "loading")

         const res = await updateStudentProfilePicture({
            newProfilePicture: reader.result,
         })

         if (res.isSuccess) {
            showMessage("Profile picture updated successfully", "success")
            setProfile({
               ...profile,
               profilePicture: reader.result,
            })
         } else {
            showMessage(
               res.message ?? "Unable to update profile picture",
               "error"
            )
         }
      }
      reader.readAsDataURL(file)
   }

   return (
      <Container className='pt-2 d-flex flex-column '>
         <MainNavbar username={profile.username} />
         <Jumbotron className='mt-2 jumbo-tron' style={{ height: "150px" }}>
            <Title className='welcome-title'>Profile Manangement</Title>
         </Jumbotron>

         <Tabs defaultActiveKey='1' className='m-5' tabPosition='left'>
            <TabPane key={1} tab='General'>
               <Row className='mt-6 mb-3'>
                  <Col span={10} className='flex flex-col'>
                     <div className='flex flex-row'>
                        <input
                           onChange={(e) => updateProfilePicture(e)}
                           type={"file"}
                           accept='.jpg, .jpej, .png'
                           id='profile'
                           className='hidden'
                        />
                        <Avatar
                           onClick={() => {
                              document.getElementById("profile").click()
                           }}
                           src={profile.profilePicture}
                           className='cursor-pointer'
                           icon={<AntDesignOutlined />}
                           size={{ xs: 24, sm: 32, md: 40, lg: 150, xl: 150 }}
                        />
                        <div className='ml-10 flex flex-col justify-center'>
                           <h2 className='text-3xl capitalize'>
                              {profile.username}
                              {" (" + profile.id + ")"}
                           </h2>
                           <h4 className=' opacity-50 text-sm ml-1'>Student</h4>
                        </div>
                     </div>
                     <div className='mt-5'>
                        <Form
                           onFinish={async (f) => {
                              setLoading(true)
                              const response = await updateStudentProfile(f)

                              setLoading(false)
                              if (response.isSuccess) {
                                 showSuccessNotification(response.message)
                              } else
                                 showErrorNotification(response.combinedErrors)

                              return false
                           }}
                           ref={profileForm}
                           layout='vertical'>
                           <label>First Name</label>
                           <Form.Item name='firstName'>
                              <Input
                                 size='large'
                                 placeholder='Enter first name'
                              />
                           </Form.Item>
                           <label>Last Name</label>
                           <Form.Item name='lastName'>
                              <Input
                                 size='large'
                                 placeholder='Enter first name'
                              />
                           </Form.Item>
                           <label>Organization</label>
                           <Form.Item name='organization'>
                              <Input
                                 size='large'
                                 placeholder='Enter organization'
                              />
                           </Form.Item>

                           <Form.Item className='flex justify-end items-end'>
                              <Button
                                 loading={loading}
                                 size='large'
                                 htmlType='submit'
                                 type='primary'>
                                 Update
                              </Button>
                           </Form.Item>
                        </Form>
                     </div>
                  </Col>
               </Row>
            </TabPane>
            <TabPane key={2} tab='Email'>
               <Row className=''>
                  <Col span={8}>
                     <Form
                        onFinish={async (f) => {
                           setLoading(true)
                           const response = await updateStudentEmail(f)
                           setLoading(false)
                           if (response.isSuccess) {
                              showSuccessNotification(response.message)
                           } else showErrorNotification(response.combinedErrors)

                           return false
                        }}
                        ref={emailForm}
                        layout='vertical'>
                        <label>New Email</label>
                        <Form.Item name='email'>
                           <Input
                              type={"email"}
                              size='large'
                              defaultValue={profile.email}
                              placeholder='Enter new email'
                           />
                        </Form.Item>

                        <Form.Item className='flex justify-end'>
                           <Button
                              loading={loading}
                              size='large'
                              htmlType='submit'
                              type='primary'>
                              Update
                           </Button>
                        </Form.Item>
                     </Form>
                  </Col>
               </Row>
            </TabPane>
            <TabPane key={3} tab='Password'>
               <Row className=''>
                  <Col span={8}>
                     <Form
                        onFinish={async (f) => {
                           console.log(f)
                           setLoading(true)
                           const response = await updateStudentPassword(f)

                           setLoading(false)
                           if (response.isSuccess) {
                              showSuccessNotification(response.message)
                           } else showErrorNotification(response.combinedErrors)

                           return false
                        }}
                        ref={passwordForm}
                        layout='vertical'>
                        <label>Current Password</label>
                        <Form.Item name='currentPassword'>
                           <Input.Password
                              size='large'
                              placeholder='Enter current password'
                           />
                        </Form.Item>
                        <label>New Password</label>
                        <Form.Item name='newPassword'>
                           <Input.Password
                              size='large'
                              placeholder='Enter new password'
                           />
                        </Form.Item>

                        <Form.Item className='flex justify-end'>
                           <Button
                              loading={loading}
                              size='large'
                              htmlType='submit'
                              type='primary'>
                              Update
                           </Button>
                        </Form.Item>
                     </Form>
                  </Col>
               </Row>
            </TabPane>
         </Tabs>
      </Container>
   )
}
