import { errorResponse } from "../../helpers/ServerResponseModel"
import { apiUrls } from "../../routes"
import { doAuthorizedGetCall, doAuthorizedPostCall } from "../AdminController"

export async function getStudentProfile() {
   try {
      return (await doAuthorizedGetCall(apiUrls.profile.get)).data
   } catch (e) {
      return errorResponse(null, "Unable to load profile")
   }
}

export async function updateStudentProfile(data) {
   try {
      return (await doAuthorizedPostCall(apiUrls.profile.updateProfile, data))
         .data
   } catch (e) {
      return errorResponse(null, "Unable to update profile")
   }
}

export async function updateStudentEmail(data) {
   try {
      return (await doAuthorizedPostCall(apiUrls.profile.updateEmail, data))
         .data
   } catch (e) {
      return errorResponse(null, "Unable to update email")
   }
}

export async function updateStudentPassword(data) {
   try {
      return (await doAuthorizedPostCall(apiUrls.profile.updatePassword, data))
         .data
   } catch (e) {
      return errorResponse(null, "Unable to update password")
   }
}

export async function updateStudentProfilePicture(data) {
   try {
      return (await doAuthorizedPostCall(apiUrls.profile.updatePicture, data))
         .data
   } catch (e) {
      return errorResponse(null, "Unable to update profile picture")
   }
}
