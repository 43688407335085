import { UploadOutlined } from "@ant-design/icons"
import { Button, Modal, Upload } from "antd"
import React, { useState } from "react"
import { importQuestions } from "../../../controllers/ExamManagementController"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"

export default function ImportQuestions({
   examId,
   onUpload,
   show,
   onClose,
   ...props
}) {
   const [ready, setReady] = useState(false)
   const [importExamViewModel, setImportExamViewModel] = useState({
      examId: examId,
      csv: "",
   })

   return (
      <Modal
         title='Import Questions'
         okText='Upload'
         visible={show}
         onOk={async () => {
            const response = await importQuestions(importExamViewModel)

            if (response.isSuccess) {
               showSuccessNotification(response.message)
               onUpload?.()
               onClose()
            } else showErrorNotification(response.combinedErrors)
         }}
         onCancel={() => {
            if (onClose) onClose()
         }}>
         <div className='text-center w-100 m-2'>
            <Upload
               maxCount={1}
               accept='.csv'
               showUploadList={false}
               beforeUpload={(file) => {
                  const reader = new FileReader()

                  reader.onload = async (e) => {
                     setImportExamViewModel({
                        ...importExamViewModel,
                        csv: e.target.result.trim(),
                     })
                     setReady(true)
                  }
                  reader.readAsText(file, "latin1")

                  // Prevent upload
                  return false
               }}
               {...props}>
               <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <div>{ready ? "Ready To Import" : "Select a CSV file"}</div>
         </div>
      </Modal>
   )
}
