import { Checkbox, Modal } from "antd"
import React, { useEffect, useState } from "react"
import {
   getAllowedGroups,
   updateAllowedGroups,
} from "../../../controllers/ExamManagementController"
import { showErrorNotification } from "../../../helpers/NotificationSystem"

export default function AllowedStudents({ examId, show, onClose, ...props }) {
   const [allowedStudents, setAllowedStudents] = useState([])
   const [updating, setUpdating] = useState(true)

   useEffect(() => {
      const update = async () => {
         const allowedStudents = await getAllowedGroups(examId)
         setAllowedStudents(allowedStudents.data)
         setUpdating(false)
      }

      update()
   }, [])

   return (
      <Modal
         okButtonProps={{ disabled: updating }}
         title={"Update Allowed Groups"}
         visible={show}
         onOk={async () => {
            setUpdating(true)

            const response = await updateAllowedGroups(
               examId,
               allowedStudents.filter((m) => m.allowed).map((s) => s.id)
            )

            setUpdating(false)

            if (response.isSuccess) {
               if (onClose !== null) {
                  onClose()
               }
            } else {
               showErrorNotification("Error")
            }
         }}
         onCancel={() => {
            if (onClose !== null) {
               onClose()
            }
         }}>
         {allowedStudents.map((a, k) => {
            return (
               <Checkbox
                  key={k + "-allowed-" + k + "" + examId}
                  checked={a.allowed}
                  onChange={(v) => {
                     setAllowedStudents(
                        allowedStudents.map((ax) => {
                           if (ax.id === a.id) {
                              ax.allowed = v.target.checked
                           }
                           return ax
                        })
                     )
                  }}>
                  {a.name}
               </Checkbox>
            )
         })}
      </Modal>
   )
}
