import { adminApiUrls } from "../routes"
import { doAuthorizedGetCall, doAuthorizedPostCall } from "./AdminController"

export async function getAllStudentGroups() {
   return (await doAuthorizedGetCall(adminApiUrls.group.all)).data
}

export async function getStudentGroupDetail(groupId) {
   return (await doAuthorizedGetCall(adminApiUrls.group.get + groupId)).data
}

export async function deleteStudentGroup(groupId) {
   return (await doAuthorizedGetCall(adminApiUrls.group.delete + groupId)).data
}

export async function addStudentGroup(groupData) {
   return (await doAuthorizedPostCall(adminApiUrls.group.add, groupData)).data
}

export async function updateStudentGroup(groupData) {
   return (await doAuthorizedPostCall(adminApiUrls.group.update, groupData))
      .data
}

export async function getGroupsOverview() {
   return (await doAuthorizedGetCall(adminApiUrls.group.overview)).data
}
