import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { Button } from "antd"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"
import { Container } from "react-bootstrap"
import Page1 from "./Page1"
import Page3 from "./Page3"
import Page2 from "./Page2"
import {
   getExamInfo,
   getSectionedExam,
   startExam,
} from "../../controllers/ExamController"
import history from "../../history"
import CustomizeExam from "./CustomizeExam"
import { getDemoPath, getExamPath } from "../../helpers/Paths"
import ExamNavBar from "../ExamNavBar"

export default function Instructions(props) {
   if (!props.location.state) history.push("/")

   useEffect(() => {
      const update = async function () {
         const examData = await getSectionedExam(examId)

         setSections(
            examData.sections.map((s) => {
               s.allow = true
               return s
            })
         )
         setName(examData.name)
      }

      update()
   }, [props])

   const { examId, mode, back } = props.location.state

   const startPage = mode === 1 ? 1 : 0

   const [name, setName] = useState("")
   const [currentPage, setCurrentPage] = useState(startPage)
   const [time, setTime] = useState(10)
   const [sections, setSections] = useState([])

   const [examInfo, setExamInfo] = useState({})

   useEffect(() => {
      const load = async function () {
         const response = await getExamInfo(examId)
         setExamInfo(response.data)
      }

      load()
   }, [])

   const pages = [
      <CustomizeExam
         sections={sections}
         setSections={(s) => setSections(s)}
         examId={props.location.state.examId}
         time={examInfo.durationInSec / 60}
         updateTime={(t) => {
            setTime(t)
         }}
      />,
      <Page1 examInfo={examInfo} examId={examId} />,
      <Page2 />,
      <Page3 />,
   ]

   const btnBackClicked = function () {
      if (currentPage === startPage) return

      setCurrentPage(currentPage - 1)
   }

   const btnNextClicked = async function () {
      if (currentPage === 0) {
         if (!time || time <= 0) {
            alert("Enter correct time!")
            return
         }

         if (sections.reduce((p, n) => (p + n.allow ? 1 : 0), 0) == 0) {
            alert("Select atleast 1 section!")
            return
         }
      }

      if (currentPage === pages.length - 1) {
         const questions = []

         sections.forEach((s) => {
            if (s.allow) {
               s.questions.forEach((q) => questions.push(q))
            }
         })

         if (mode === 2) {
            history.push({
               pathname: getDemoPath(),
               exam: {
                  name: name,
                  questions: questions,
                  totalSeconds: time * 60,
               },
            })
         }

         const result = await startExam(examId, mode)

         if (result.isSuccess) {
            if (mode === 1) history.push(getExamPath(result.data.examId))
            else
               history.push({
                  pathname: getDemoPath(),
                  exam: {
                     name: result.data.name,
                     questions: result.data.questions,
                  },
               })
         } else {
            alert("Unknow error occured on server!")
         }

         return
      }

      setCurrentPage(currentPage + 1)
   }

   return (
      <>
         <ExamNavBar
            remainingTime={time}
            examName={name}
            isStarted={false}
            back={back}
            questionsCount={sections.questionsCount}
         />
         <Container style={{ paddingBottom: "81px" }}>
            {pages[currentPage]}
         </Container>
         <div className='fixed-bottom bg-light p-5 border-top'>
            <Container className='d-flex justify-content-between'>
               <Button
                  size='large'
                  onClick={btnBackClicked}
                  className='d-flex align-items-center px-5'
                  disabled={currentPage > startPage ? false : true}>
                  <ArrowLeftOutlined />
                  Previous
               </Button>

               <Button
                  type='primary'
                  size='large'
                  className='d-flex align-items-center px-5'
                  onClick={btnNextClicked}>
                  {currentPage === pages.length - 1 ? "Begin" : "Next"}
                  <ArrowRightOutlined />
               </Button>
            </Container>
         </div>
      </>
   )
}
