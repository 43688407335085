import {
   Button,
   Col,
   Image,
   Input,
   Row,
   Select,
   Typography,
   Upload,
} from "antd"
import React from "react"
import { useState } from "react"
import {
   deleteQuestion,
   updateQuestion,
} from "../../../controllers/ExamManagementController"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import Answers from "./Answers"

const { Search } = Input

const { Title, Text } = Typography

export default function EditQuestion({
   examId,
   question,
   onQuestionDeleted,
   onQuestionUpdated,
   sectionId,
   ...props
}) {
   const [updatedQuestion, setUpdatedQuestion] = useState({
      ...question,
      examId: examId,
   })

   const handleChange = (n, e) => {
      setUpdatedQuestion({
         ...updatedQuestion,
         [n]: e.target.value,
      })
   }

   return (
      <div>
         <div className='border div-box rounded p-5'>
            <Title className='ml-1 mb-5' level={4}>
               Update Question
            </Title>
            <Row gutter={24}>
               <Col span={16}>
                  <Input
                     size='large'
                     value={updatedQuestion.text}
                     prefix={
                        <label className='m-0 opacity-50'>Question Text:</label>
                     }
                     onChange={(e) => {
                        handleChange("text", e)
                     }}
                  />
               </Col>
               <Col span={4}>
                  <Input
                     size='large'
                     value={updatedQuestion.points}
                     type='number'
                     min='1'
                     prefix={<label className='m-0 opacity-50'>Points:</label>}
                     onChange={(e) => {
                        handleChange("points", e)
                     }}
                  />
               </Col>
               <Col span={4}>
                  <Select
                     size='large'
                     className='w-100'
                     defaultValue={updatedQuestion.answerType + ""}
                     onChange={(v) => handleChange("answerType", parseInt(v))}>
                     <Select.Option value='1'>Single</Select.Option>
                     <Select.Option value='2'>Multiple</Select.Option>
                  </Select>
               </Col>
            </Row>

            <div className='d-flex justify-content-between mt-5'>
               <div className='d-flex flex-column'>
                  <div>
                     <Image width={100} src={updatedQuestion.imageUrl} />
                  </div>
                  <Upload
                     fileList={[]}
                     accept='image/jpeg'
                     customRequest={(file, url) => {}}
                     onChange={(info) => {
                        const reader = new FileReader()
                        reader.addEventListener("load", () =>
                           setUpdatedQuestion({
                              ...updatedQuestion,
                              imageUrl: reader.result,
                           })
                        )
                        reader.readAsDataURL(info.file.originFileObj)
                     }}>
                     <Button size='large' type='dashed'>
                        Upload Image
                     </Button>
                  </Upload>
               </div>

               <div className='d-flex justify-content-end align-items-end'>
                  <Button
                     danger
                     size='large'
                     type='ghost'
                     className='mr-5'
                     onClick={async () => {
                        const response = await deleteQuestion(
                           question.id,
                           examId
                        )

                        if (!response.isSuccess) {
                           showErrorNotification(response.combinedErrors)
                        } else onQuestionDeleted(question.id)
                     }}>
                     Delete Question
                  </Button>
                  <Button
                     size='large'
                     type='primary'
                     onClick={async () => {
                        const response = await updateQuestion(updatedQuestion)

                        if (response.isSuccess) {
                           onQuestionUpdated(response.data)

                           showSuccessNotification("Question Updated")
                        } else {
                           showErrorNotification(response.combinedErrors)
                        }
                     }}>
                     Update Question
                  </Button>
               </div>
            </div>
         </div>
         <div className='border div-box rounded mt-5 p-5'>
            <Title className='ml-1 mb-5' level={4}>
               Add Options
            </Title>
            <Answers
               examId={examId}
               sectionId={sectionId}
               onUpdate={(answers) => {
                  setUpdatedQuestion({ ...updatedQuestion, answers: answers })
               }}
               questionId={updatedQuestion.id}
               questionsAnswers={updatedQuestion.answers}
            />
         </div>
      </div>
   )
}
