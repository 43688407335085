export function shuffleArray(array) {
   for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1))
      var temp = array[i]
      array[i] = array[j]
      array[j] = temp
   }

   return array
}

export function downloadLocalFile(fileType, data, fileName) {
   var ft

   switch (fileType) {
      case "csv":
         ft = "text/csv"
         break

      default:
         ft = "text/plain"
         break
   }

   const file = new Blob([data], { type: ft })
   var link = document.createElement("a")
   link.href = URL.createObjectURL(file)
   link.download = fileName
   document.body.appendChild(link) // Required for FF
   link.click()
   document.body.removeChild(link)
}
