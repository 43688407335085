import React from "react"
import { Container, Navbar } from "react-bootstrap"
import { Button, PageHeader, Modal, Popconfirm } from "antd"
import {
   ClockCircleOutlined,
   ExclamationCircleOutlined,
   ArrowLeftOutlined,
} from "@ant-design/icons"
import history from "../history"
import "./Student.css"

export default function ExamNavbar(props) {
   function confirm() {
      Modal.confirm({
         title: "Confirm",
         icon: <ExclamationCircleOutlined />,
         onOk: () => (props.back ? history.goBack() : history.push("/")),
         content: "Sure you want to exit the exam?",
         okText: "Exit",
         cancelText: "Cancel",
      })
   }

   return (
      <div className='exam-navbar sticky-top'>
         <Container className='d-flex justify-content-between '>
            <PageHeader
               backIcon={
                  <ArrowLeftOutlined
                     style={{ color: "white", fontSize: "18px" }}
                  />
               }
               className='px-0'
               onBack={() => confirm()}
               title={<span className='text-white'>{props.examName}</span>}
               subTitle={
                  <span className='text-white'>
                     {props.questionsCount} Questions
                  </span>
               }
            />

            <div className='d-flex align-items-center justify-content-end'>
               {props.isStarted ? (
                  <Popconfirm
                     title='Sure to finish the exam?'
                     onConfirm={props.onFinishExam}>
                     <Button
                        size='large'
                        type='danger'
                        className='mr-2 rounded'>
                        Finish
                     </Button>
                  </Popconfirm>
               ) : (
                  <></>
               )}
               <div className='remaining-time-div d-flex align-items-center justify-content-center my-auto'>
                  <ClockCircleOutlined className='mr-3' />
                  {props.remainingTime}
               </div>
            </div>
         </Container>
      </div>
   )
}
