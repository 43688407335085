import React, { useState } from "react"
import { Button } from "antd"
import { FaChartBar, FaLock, FaPencilAlt, FaUser } from "react-icons/fa"
import {
   ProSidebar,
   Menu,
   MenuItem,
   SidebarContent,
   SidebarHeader,
   SidebarFooter,
} from "react-pro-sidebar"
import "react-pro-sidebar/dist/css/styles.css"
import { Link, Route, Switch } from "react-router-dom"
import {
   clearUserData,
   getUsername,
   getUserType,
   isForeignAdmin,
} from "../../controllers/Authorization"
import { adminPaths } from "../../helpers/Paths"
import UserManagement from "./UserManagement/UserManagement"
import UserInformation from "./UserManagement/UserInformation"
import history from "./../../history"
import ExamManagementView from "./ExamManagement/ExamManagementView"
import ManageExams from "./ExamManagement/ManageExams"
import Dashboard from "./Dashboard"
import MainLogo from "../../assets/Logo.png"
import "./Dashboard.css"
import { getUserKey } from "../../helpers/UserHelper"
import GroupManagementView from "./GroupManagement/GroupManagementView"
import GroupView from "./GroupManagement/GroupView"
import APITokenManagement from "./ForeignAdmin/APITokenManagement"
import { updatePermission } from "../../helpers/Permissions"
import { useEffect } from "react"
import { userTypes } from "../../helpers/types"
import { getAppVersion } from "../../helpers/versionInfo"

export default function AdminDashboard() {
   const [permissions, setPermissions] = useState({
      exam: {
         add: getUserType() === userTypes.admin,
         update: true,
         delete: getUserType() === userTypes.admin,
      },
      groups: getUserType() === userTypes.admin,
      apiTokenManagement: isForeignAdmin(),
   })

   const [isPermissionUpdated, setIsPermissionUpdated] = useState(false)

   useEffect(() => {
      updatePermission(permissions)
      setIsPermissionUpdated(true)
   }, [permissions])

   if (!isPermissionUpdated) return <></>

   return (
      <div className='d-flex flex-grow-1 h-100 w-100 bg-light-gray'>
         <div>
            <ProSidebar style={{ zIndex: "0" }}>
               <SidebarHeader id='sidebar-header'>
                  <img
                     className='cursor-pointer'
                     onClick={() => {
                        history.push(adminPaths.dashboard)
                     }}
                     src={MainLogo}
                     id='main-logo'
                     alt='OnionCert Logo'
                  />
                  <h3 className='text-center p-2 text-white mb-5'>
                     Admin Panel
                  </h3>
               </SidebarHeader>
               <SidebarContent id='sidebar-content'>
                  <Menu iconShape='square'>
                     <MenuItem icon={<FaChartBar />}>
                        <Link to={adminPaths.dashboard}>Dashboard</Link>
                     </MenuItem>
                     {getUserType() === getUserKey("admin") ? (
                        <>
                           <MenuItem icon={<FaUser />}>
                              <Link to={adminPaths.groupManagement}>
                                 Group Management
                              </Link>
                           </MenuItem>
                           <MenuItem icon={<FaUser />}>
                              <Link to={adminPaths.userManagement}>
                                 User Management
                              </Link>
                           </MenuItem>
                        </>
                     ) : (
                        <></>
                     )}
                     <MenuItem icon={<FaPencilAlt />}>
                        <Link to={adminPaths.examManagement}>
                           Exam Management
                        </Link>
                     </MenuItem>
                     {permissions.apiTokenManagement && (
                        <MenuItem icon={<FaLock />}>
                           <Link to={adminPaths.tokenManagement}>
                              Token Management
                           </Link>
                        </MenuItem>
                     )}
                  </Menu>
               </SidebarContent>
               <SidebarFooter className='text-center'>
                  <span className='d-block mt-2'>{getUsername()}</span>
                  <Button
                     ghost
                     danger
                     className='mt-3 mb-2'
                     onClick={() => {
                        clearUserData()
                        history.push(adminPaths.login)
                     }}>
                     Logout
                  </Button>
                  <p className='m-0 p-0 mb-2'>{getAppVersion()}</p>
               </SidebarFooter>
            </ProSidebar>
         </div>

         <div className='d-flex flex-column h-100 w-100 p-2 bg-white'>
            <Switch>
               <Route exact path={adminPaths.dashboard}>
                  <Dashboard />
               </Route>

               <Route exact path={adminPaths.groupManagement}>
                  <GroupManagementView />
               </Route>

               <Route
                  component={(p) => <GroupView {...p} />}
                  exact
                  path={adminPaths.groupManagement + "/:id"}
               />

               <Route exact path={adminPaths.userManagement}>
                  <UserManagement />
               </Route>
               <Route
                  exact
                  component={(p) => <UserInformation {...p} />}
                  path={adminPaths.userManagement + "/user/:type/:id"}></Route>

               <Route exact path={adminPaths.examManagement}>
                  <ExamManagementView />
               </Route>

               <Route exact path={adminPaths.tokenManagement}>
                  <APITokenManagement />
               </Route>

               <Route
                  component={(p) => <ManageExams {...p} />}
                  exact
                  path={adminPaths.examManagement + "/exam/:id"}></Route>
            </Switch>
         </div>
      </div>
   )
}
