import Axios from "axios"
import { getAuthorizationHeader } from "./Authorization"
import { apiUrls } from "../routes"

export async function getExamInfo(examId) {
   return (await doAuthorizedGetCall(apiUrls.examInfo + examId)).data
}

export async function getExams() {
   const result = await doAuthorizedGetCall(apiUrls.exams)
   return result.data
}

export async function startExam(examId, mode) {
   const result = await doAuthorizedGetCall(
      apiUrls.startExam + `${examId}?mode=${mode}`
   )

   return result.data
}

export async function getSectionedExam(examId) {
   if (!examId) return null

   return (
      await doAuthorizedGetCall(apiUrls.sectionedExam + "?examId=" + examId)
   ).data
}

export async function continueExam(takenExamId) {
   const result = await doAuthorizedGetCall(apiUrls.continueExam + takenExamId)

   return result.data
}

export async function submitQuestion(id, qi, ai) {
   var s = ""

   ai.forEach((a) => (s += `answerId=${a}&`))

   var p = apiUrls.submit.replace("{id}", id)
   p = `${p}?questionId=${qi}&${s}`

   return (await doAuthorizedGetCall(p)).data
}

export async function finishExam(id) {
   var url = apiUrls.finishExam + id

   return (await doAuthorizedGetCall(url)).data
}

async function doAuthorizedGetCall(url) {
   return await Axios.get(url, {
      headers: getAuthorizationHeader(),
   })
}

async function doAuthorizedPostCall(url, data) {
   return await Axios.get(url, {
      data: data,
      headers: getAuthorizationHeader(),
   })
}

export async function getExamResult(id) {
   return (await doAuthorizedGetCall(apiUrls.result + id)).data
}

export async function getStudentTakenExams() {
   try {
      return (await doAuthorizedGetCall(apiUrls.exam.taken)).data
   } catch (e) {
      return {
         data: [],
         isSuccess: false,
         message: "Unable to taken exams",
      }
   }
}
