import React, { useEffect, useState } from "react"
import { Button, Table } from "antd"
import ViewStudentResult from "./ViewStudentResult"

export default function StudentTakenExam({
   from = "admin",
   exams,
   studentId,
   ...props
}) {
   const [takenExams, setTakenExams] = useState([])
   useEffect(() => {
      setTakenExams(exams)
   }, [exams])

   const showExamResult = (id, show) => {
      setTakenExams(
         takenExams.map((t) => {
            if (t.id === id) {
               return {
                  ...t,
                  showResult: show,
               }
            }

            return t
         })
      )
   }

   const columns = [
      {
         title: "Exam Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Exam Date",
         dataIndex: "startTime",
         key: "startDate",
      },
      {
         title: "Report",
         render: (text, record) => {
            return (
               <>
                  <ViewStudentResult
                     show={record.showResult}
                     studentId={studentId}
                     from={from}
                     examId={record.id}
                     onClose={() => {
                        showExamResult(record.id, false)
                     }}
                  />
                  <Button onClick={() => showExamResult(record.id, true)}>
                     View
                  </Button>
               </>
            )
         },
      },
   ]

   return <Table dataSource={takenExams} columns={columns} />
}
