export const ServerResponse = {
    isSuccess: false,
    message: null,
    errors: [],
    data: null
}

export function okResponse(data=null, message="")
{
    const serverResponse = ServerResponse;

    serverResponse.data = data;
    serverResponse.message = message;
    serverResponse.isSuccess = true;

    return serverResponse;
}

export function errorResponse(errors=null, message="", data=null)
{
    const serverResponse = ServerResponse;

    serverResponse.data = data;
    serverResponse.message = message;
    serverResponse.isSuccess = false;
    serverResponse.errors = errors;
    
    return serverResponse;
}