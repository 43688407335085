import {
   Col,
   Row,
   PageHeader,
   Breadcrumb,
   Typography,
   Input,
   Switch,
   Button,
   InputNumber,
} from "antd"
import React, { useEffect, useState } from "react"
import {
   getExamById,
   deleteExam,
   exportQuestions,
   updateExam,
} from "../../../controllers/ExamManagementController"
import { adminPaths } from "../../../helpers/Paths"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import AllowedStudents from "./AllowedStudents"
import { downloadLocalFile } from "../../../helpers/Helpers"
import ImportQuestions from "./ImportQuestions"
import history from "../../../history"
import Sections from "./Sections"
import { Permissions } from "../../../helpers/Permissions"
import useAdmin from "../../../hooks/useAdmin"
import useUser from "../../../hooks/useUser"

export default function ManageExams(props) {
   const { id } = props.match.params
   const [exam, setExam] = useState(null)
   const [showAllowedExam, setShowAllowedExam] = useState(false)
   const [showImport, setShowImport] = useState(false)
   const loadExam = async () => {
      const response = await getExamById(id)
      if (response.isSuccess) {
         setExam({
            ...response.data,
            updated: response.data,
         })
      } else {
         history.push(adminPaths.examManagement)
      }
   }
   useEffect(() => {
      loadExam()
   }, [])

   const handleExamUpdateInput = (name, value) => {
      setExam({
         ...exam,
         updated: { ...exam.updated, [name]: value },
      })
   }

   const { Title } = Typography
   const { TextArea } = Input
   const admin = useUser()

   useEffect(() => {
      if (!admin) return

      if (admin.isForeign) setAllowAdminEdits(false)
      if (admin.type !== 2) setAllowAdminEdits(false)
   }, [admin])

   const [allowAdminEdits, setAllowAdminEdits] = useState(true)

   const render = () => {
      if (exam == null) return <></>

      return (
         <div className='h-100 overflow-auto'>
            <AllowedStudents
               examId={exam.id}
               onClose={() => setShowAllowedExam(false)}
               show={showAllowedExam}
            />
            <ImportQuestions
               onUpload={() => {
                  loadExam()
               }}
               examId={exam.id}
               show={showImport}
               onClose={() => {
                  setShowImport(false)
               }}
            />
            <div className='m-5'>
               <PageHeader
                  onBack={() => history.goBack()}
                  className='p-0'
                  title='Exam Information'
               />
               <Breadcrumb className='ml-4 pl-4 mt-1'>
                  <Breadcrumb.Item>Exam Management</Breadcrumb.Item>
                  <Breadcrumb.Item>Exam Information</Breadcrumb.Item>
               </Breadcrumb>

               <div className='p-5 mt-5 border div-box rounded mb-5'>
                  <Title className='mb-5' level={4}>
                     Basic Info
                  </Title>
                  <Row gutter={24}>
                     <Col span={8}>
                        <label for='name'>Exam Name:</label>
                        <Input
                           size='large'
                           value={exam.updated.name}
                           name='name'
                           id='name'
                           onChange={(e) =>
                              handleExamUpdateInput(
                                 e.target.name,
                                 e.target.value
                              )
                           }
                        />
                     </Col>
                     <Col span={4}>
                        <label for='mquestions'>Max Questions</label>
                        <InputNumber
                           id='mquestions'
                           size='large'
                           min={1}
                           value={exam.updated.maxQuestions}
                           name='maxQuestions'
                           onChange={(e) =>
                              handleExamUpdateInput("maxQuestions", e)
                           }
                        />
                     </Col>
                     <Col className='d-flex flex-column' span={4}>
                        <label for='durationInSec'>Duration:</label>
                        <InputNumber
                           size='large'
                           className='w-100'
                           value={exam.updated.durationInSec / 60}
                           name='durationInSec'
                           id='durationInSec'
                           onChange={(e) => {
                              handleExamUpdateInput("durationInSec", e * 60)
                           }}
                        />
                     </Col>
                     <Col className='d-flex flex-column' span={4}>
                        <label for='passingScore'>Passing Score:</label>
                        <InputNumber
                           className='w-100'
                           size='large'
                           value={exam.updated.passingScore}
                           name='passingScore'
                           id='passingScore'
                           onChange={(e) =>
                              handleExamUpdateInput("passingScore", e)
                           }
                        />
                     </Col>
                     <Col span={4} className='d-flex flex-column'>
                        <label for='allowDemo'>Allow Demo</label>
                        <Switch
                           className='w-25 mt-1'
                           size='large'
                           checked={exam.updated.allowDemo}
                           name='allowDemo'
                           id='allowDemo'
                           onChange={(e) => {
                              handleExamUpdateInput("allowDemo", e)
                           }}>
                           Allow Demo
                        </Switch>
                     </Col>
                  </Row>
                  <Row gutter={24} className='mt-5'>
                     <Col span={12}>
                        <label for='instructions'>
                           Enter Exam Instructions
                        </label>
                        <TextArea
                           rows={4}
                           value={exam.updated.instructions}
                           name='instructions'
                           id='instructions'
                           onChange={(e) =>
                              handleExamUpdateInput(
                                 e.target.name,
                                 e.target.value
                              )
                           }
                        />
                     </Col>
                     <Col span={12}>
                        <label for='testInformation'>
                           Enter Exam Information
                        </label>
                        <TextArea
                           rows={4}
                           value={exam.updated.testInformation}
                           name='testInformation'
                           id='testInformation'
                           onChange={(e) =>
                              handleExamUpdateInput(
                                 e.target.name,
                                 e.target.value
                              )
                           }
                        />
                     </Col>
                  </Row>

                  <Row className='mt-5'>
                     <Col span={12}>
                        <div className='mr-3'>
                           <label for='passingScore'>Teachers:</label>
                           <Input
                              className='w-100'
                              size='large'
                              value={exam.updated.teachers}
                              id='teachers'
                              name='teachers'
                              readOnly={!allowAdminEdits}
                              onChange={(e) => {
                                 if (!allowAdminEdits) return
                                 handleExamUpdateInput(
                                    "teachers",
                                    e.target.value
                                 )
                              }}
                           />
                        </div>
                     </Col>
                     <Col span={6}>
                        <div className='ml-3'>
                           <label for='passingScore'>Correlation ID:</label>
                           <Input
                              className='w-100'
                              size='large'
                              value={exam.updated.correlationId}
                              id='correlationId'
                              name='correlationId'
                              min={0}
                              readOnly={!allowAdminEdits}
                              onChange={(e) => {
                                 if (!allowAdminEdits) return
                                 handleExamUpdateInput(
                                    "correlationId",
                                    e.target.value
                                 )
                              }}
                           />
                        </div>
                     </Col>
                     <Col span={6}>
                        <div className='ml-3'>
                           <label for='passingScore'>Exam Type:</label>
                           <InputNumber
                              className='w-100'
                              size='large'
                              readOnly={!allowAdminEdits}
                              value={exam.updated.examType}
                              id='examType'
                              name='examType'
                              min={0}
                              onChange={(e) => {
                                 if (!allowAdminEdits) return
                                 handleExamUpdateInput("examType", parseInt(e))
                              }}
                           />
                        </div>
                     </Col>
                  </Row>

                  <Row gutter={24} className='mt-5'>
                     <Col className='d-flex' span={12}>
                        <div className='d-flex flex-column ml-5'>
                           <label for='allowAllStudents'>Is Exam Cert:</label>
                           <Switch
                              className='w-25'
                              size='large'
                              id='isExamCert'
                              name='isExamCert'
                              readOnly={!allowAdminEdits}
                              checked={exam.updated.isExamCert}
                              onChange={(e) => {
                                 if (!allowAdminEdits) return
                                 handleExamUpdateInput("isExamCert", e)
                              }}
                           />
                        </div>

                        <div className='d-flex flex-column ml-5'>
                           <label for='allowAllStudents'>Is Foreign:</label>
                           <Switch
                              className='w-25'
                              size='large'
                              id='isForeign'
                              name='isForeign'
                              readOnly={!allowAdminEdits}
                              checked={exam.updated.isForeign}
                              onChange={(e) => {
                                 if (!allowAdminEdits) return
                                 handleExamUpdateInput("isForeign", e)
                              }}
                           />
                        </div>

                        <div className='d-flex flex-column ml-5'>
                           <label for='isReady'>Is Ready:</label>
                           <Switch
                              className='w-25'
                              size='large'
                              id='isReady'
                              name='isReady'
                              checked={exam.updated.isReady}
                              onChange={(e) => {
                                 handleExamUpdateInput("isReady", e)
                              }}
                           />
                        </div>
                     </Col>
                  </Row>
                  <div className='d-flex justify-content-between mt-5 pt-5'>
                     <div>
                        <Button
                           type='dashed'
                           size='large'
                           onClick={async () => {
                              const questions = await exportQuestions(exam.id)
                              downloadLocalFile(
                                 "csv",
                                 questions.data,
                                 "questions.csv"
                              )
                           }}>
                           Export Questions
                        </Button>
                        <Button
                           type='dashed'
                           size='large'
                           className='ml-5'
                           onClick={() => {
                              setShowImport(true)
                           }}>
                           Import Questions
                        </Button>
                        <Button
                           size='large'
                           className='ml-5'
                           onClick={() => {
                              setShowAllowedExam(true)
                           }}>
                           Allowed Groups
                        </Button>
                     </div>
                     <div>
                        <Button
                           danger
                           size='large'
                           className={
                              "mr-5 " +
                              (Permissions.exam.delete ? "" : "d-none")
                           }
                           onClick={async () => {
                              const response = await deleteExam(exam.id)
                              if (response.isSuccess) {
                                 history.goBack()
                              }
                           }}>
                           Delete
                        </Button>
                        <Button
                           size='large'
                           type='primary'
                           className={Permissions.exam.update ? "" : "d-none"}
                           onClick={async () => {
                              const response = await updateExam(exam.updated)
                              if (response.isSuccess) {
                                 showSuccessNotification("Updated")

                                 setExam({
                                    ...exam.updated,
                                    updated: exam.updated,
                                 })
                              } else
                                 showErrorNotification(response.combinedErrors)
                           }}>
                           Update Exam
                        </Button>
                     </div>
                  </div>
               </div>

               {exam == null ? (
                  <></>
               ) : (
                  <Sections
                     examId={parseInt(id)}
                     examSections={exam.sections}
                  />
               )}
            </div>
         </div>
      )
   }

   return render()
}
