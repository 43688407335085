import {
   Button,
   Checkbox,
   DatePicker,
   Input,
   message,
   Space,
   Spin,
   Table,
   Typography,
} from "antd"
import React, { useEffect, useState } from "react"
import { Permissions } from "../../../helpers/Permissions"
import moment from "moment"
import {
   addNewApiToken,
   deleteToken,
   getAllTokens,
} from "../../../controllers/ApiTokenController"
import { CopyFilled, LoadingOutlined } from "@ant-design/icons"
const { Title } = Typography

export default function APITokenManagement() {
   const [noExpiry, setNoExpiry] = useState(true)
   const [expiryDate, setExpiryDate] = useState(null)
   const [loadingAdd, setLoadingAdd] = useState(false)
   const [loadingLoad, setLoadingLoad] = useState(false)
   const [tokens, setTokens] = useState([])

   const addToken = async () => {
      setLoadingAdd(true)
      message.info("Adding new token...")
      const response = await addNewApiToken({
         noExpiry: noExpiry,
         expiryDate: expiryDate ?? new Date(),
      })
      setLoadingAdd(false)
      if (response.isSuccess) {
         message.success("Token added successfully")
         setTokens([...tokens, response.data])
      } else {
         message.error("Error while adding token")
      }
   }

   useEffect(() => {
      const loadTokens = async () => {
         setLoadingLoad(true)
         var response = await getAllTokens()
         setLoadingLoad(false)

         if (response.isSuccess) {
            setTokens(response.data)
         } else message.info("Error while loading tokens")
      }

      loadTokens()
   }, [])

   if (!Permissions.apiTokenManagement)
      return (
         <div className='flex flex-1 justify-center items-center'>
            <h2>API Token Management Is Only Allowed For Foreign Admins</h2>
         </div>
      )
   return (
      <div className='overflow-auto flex flex-1'>
         <div className='m-5 flex flex-col flex-1'>
            <Title className='main-title mb-5' level={2}>
               API Token Management
            </Title>

            <div className={"p-5 mt-5 border div-box rounded mb-5"}>
               <Title className='mb-5' level={4}>
                  Generate New Api Token
               </Title>

               <div className='flex flex-1 flex-row'>
                  <div className='flex flex-1'>
                     <Checkbox
                        className='self-center'
                        checked={noExpiry}
                        onChange={(e) => setNoExpiry(e.target.checked)}>
                        No Expiry
                     </Checkbox>

                     {!noExpiry && (
                        <DatePicker
                           onChange={(e) => setExpiryDate(e.toDate())}
                        />
                     )}
                  </div>
                  <Button
                     loading={loadingAdd}
                     type='primary'
                     onClick={addToken}>
                     Generate Token
                  </Button>
               </div>
            </div>
            <div
               className={
                  "p-5 mt-5 border div-box rounded mb-5 flex flex-col flex-1"
               }>
               <Title className='mb-5' level={4}>
                  Tokens
               </Title>

               {loadingLoad ? (
                  <>
                     <div className='flex flex-1 justify-center items-center'>
                        <Spin indicator={<LoadingOutlined />} />
                     </div>
                  </>
               ) : (
                  <Table
                     dataSource={tokens}
                     columns={[
                        {
                           title: "Token",
                           dataIndex: "token",
                           key: "token",
                           render: (text, record) => {
                              return (
                                 <Input.Password
                                    value={text}
                                    readOnly
                                    addonAfter={
                                       <Button
                                          onClick={() => {
                                             navigator.clipboard.writeText(text)
                                             message.info("Copied to clipboard")
                                          }}
                                          size='small'
                                          icon={<CopyFilled />}
                                          className='m-0 p-0'
                                       />
                                    }
                                 />
                              )
                           },
                        },
                        {
                           title: "Expiry Date",
                           dataIndex: "expiryDate",
                           key: "expiryDate",
                           render: (text, record) => {
                              if (record.noExpiry) return <span>No Expiry</span>

                              return (
                                 <span>
                                    {moment(record.expiryDate).format(
                                       "DD-MM-YYYY"
                                    )}
                                 </span>
                              )
                           },
                        },
                        {
                           title: "Action",
                           dataIndex: "action",
                           key: "action",
                           render: (text, record) => {
                              return (
                                 <Space>
                                    <Button
                                       onClick={async () => {
                                          setTokens(
                                             tokens.map((t) => {
                                                if (t.id === record.id) {
                                                   return {
                                                      ...t,
                                                      deleting: true,
                                                   }
                                                }

                                                return t
                                             })
                                          )
                                          const response = await deleteToken(
                                             record.id
                                          )
                                          if (response.isSuccess) {
                                             setTokens(
                                                tokens.filter(
                                                   (t) => t.id !== record.id
                                                )
                                             )
                                             message.success(
                                                "Token deleted successfully"
                                             )
                                          } else {
                                             message.error(
                                                "Error while deleting token"
                                             )
                                             setTokens(
                                                tokens.map((t) => {
                                                   if (t.id === record.id) {
                                                      return {
                                                         ...t,
                                                         deleting: false,
                                                      }
                                                   }

                                                   return t
                                                })
                                             )
                                          }
                                       }}
                                       loading={record.deleting}
                                       size='small'
                                       danger>
                                       Delete
                                    </Button>
                                 </Space>
                              )
                           },
                        },
                     ]}
                  />
               )}
            </div>
         </div>
      </div>
   )
}
