import React, { useEffect, useState } from "react"
import {
   Typography,
   Col,
   Collapse,
   Row,
   Input,
   Button,
   Divider,
   InputNumber,
} from "antd"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import {
   addSection,
   deleteSection,
   updateSection,
} from "../../../controllers/ExamManagementController"
import Questions from "./Questions"
import { ExamManagementColors } from "../../../helpers/Colors"

const { Panel } = Collapse
const { Text, Title } = Typography
const { Search } = Input

export default function Sections({ examId, examSections, ...props }) {
   const [sections, setSections] = useState(
      examSections.map((s) => {
         s.updated = {
            id: s.id,
            examId: examId,
            name: s.name,
            percentage: s.percentage,
         }
         s.searchedQuestions = s.questions
         return s
      })
   )

   const [newSection, setNewSection] = useState({
      name: "",
      id: 0,
      examId: examId,
      percentage: 0,
   })
   return (
      <>
         <Divider orientation='left'>Add Section</Divider>
         <div className='d-flex justify-content-between border add-section my-5'>
            <div className='flex w-100 m-2 items-center'>
               <div className='flex flex-row flex-1'>
                  <div className='flex flex-col'>
                     <h3 className='ml-1'>Name</h3>
                     <Input
                        className='flex-grow-1'
                        size='large'
                        placeholder='Enter Section Name'
                        type='text'
                        name='name'
                        value={newSection.name}
                        onChange={(event) => {
                           setNewSection({
                              ...newSection,
                              name: event.target.value,
                           })
                        }}
                     />
                  </div>
                  <div className='flex flex-col ml-2'>
                     <h3 className='ml-2'>Percentage</h3>
                     <InputNumber
                        min={0}
                        className='w-50 ml-2 mr-2'
                        size='large'
                        placeholder='Enter Section Percentage'
                        type='text'
                        name='percentage'
                        value={newSection.percentage}
                        onChange={(value) => {
                           setNewSection({
                              ...newSection,
                              percentage: value,
                           })
                        }}
                     />
                  </div>
               </div>
               <Button
                  type='primary'
                  size='large'
                  className='justify-content-end'
                  onClick={async () => {
                     const ns = newSection
                     if (ns.percentage === 0 || ns.percentage === null) {
                        ns.percentage = 100
                     }
                     const response = await addSection(ns)
                     if (response.isSuccess) {
                        const { data } = response
                        data.updated = {
                           id: data.id,
                           examId: examId,
                           name: data.name,
                           percentage: data.percentage,
                        }
                        data.searchedQuestions = data.questions
                        setSections([...sections, response.data])
                        showSuccessNotification("Section Added")
                     } else showErrorNotification("Unable to add section")
                  }}>
                  Add Section
               </Button>
            </div>
         </div>

         <Collapse className='my-5' defaultActiveKey={[0]}>
            {sections.map((section, index) => (
               <Panel header={section.name} key={index}>
                  <Divider className='px-5' orientation='left'>
                     Update Section
                  </Divider>

                  <div className='d-flex justify-content-between m-5'>
                     <div className='d-flex justify-content-between'>
                        <div className='d-flex mr-5'>
                           <div className='flex flex-col'>
                              <h3 className='ml-1'>Name</h3>
                              <Input
                                 size='large'
                                 placeholder='New Section Name'
                                 type='text'
                                 name='name'
                                 value={section.updated.name}
                                 onChange={(event) => {
                                    setSections(
                                       sections.map((s) => {
                                          if (s.id === section.id) {
                                             return {
                                                ...s,
                                                updated: {
                                                   ...s.updated,
                                                   name: event.target.value,
                                                },
                                             }
                                          }
                                          return s
                                       })
                                    )
                                 }}
                              />
                           </div>
                           <div className='flex flex-col'>
                              <h3 className='ml-2'>Percentage</h3>
                              <InputNumber
                                 min={0}
                                 className='w-50 ml-2 mr-2'
                                 size='large'
                                 placeholder='Enter Section Percentage'
                                 type='text'
                                 name='percentage'
                                 value={section.updated.percentage}
                                 onChange={(value) => {
                                    setSections(
                                       sections.map((s) => {
                                          if (s.id === section.id) {
                                             return {
                                                ...s,
                                                updated: {
                                                   ...s.updated,
                                                   percentage: value,
                                                },
                                             }
                                          }
                                          return s
                                       })
                                    )
                                 }}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='d-flex justify-content-end m-5'>
                     <Button
                        danger
                        size='large'
                        type='ghost'
                        className='mx-2'
                        onClick={async () => {
                           const response = await deleteSection(
                              section.id,
                              examId
                           )
                           if (response.isSuccess) {
                              showSuccessNotification("Section Delete")

                              setSections(
                                 sections
                                    .map((s) => {
                                       if (s.id == section.id) {
                                          return null
                                       }

                                       return s
                                    })
                                    .filter((s) => s != null)
                              )
                           } else
                              showErrorNotification("Unable to delete section")
                        }}>
                        Delete Section
                     </Button>
                     <Button
                        ghost
                        size='large'
                        type='primary'
                        onClick={async () => {
                           const response = await updateSection(section.updated)

                           if (response.isSuccess) {
                              setSections(
                                 sections.map((s) => {
                                    if (s.id == section.id) {
                                       s.name = s.updated.name
                                    }

                                    return s
                                 })
                              )
                              showSuccessNotification("Section Added")
                           } else showErrorNotification("Unable to add section")
                        }}>
                        Update Section
                     </Button>
                  </div>

                  <Divider className='px-5' orientation='left'>
                     Add Question
                  </Divider>

                  <Questions
                     examId={examId}
                     sectionId={section.id}
                     sectionQuestions={section.searchedQuestions}
                  />
               </Panel>
            ))}
         </Collapse>
      </>
   )
}
