import Axios from "axios"
import { getAuthorizationHeader } from "./Authorization"
import { errorResponse, ServerResponse } from "../helpers/ServerResponseModel"
import { adminApiUrls, apiUrls } from "../routes"
import { Log } from "./Logger"
import { doAuthorizedGetCall } from "./AdminController"

export async function getSelf() {
   try {
      const response = await doAuthorizedGetCall(apiUrls.account.me)

      return response.data
   } catch (e) {
      Log(e)
      return errorResponse(null, e.message)
   }
}

export async function Login(authenticationModel, type = 1) {
   const response = ServerResponse
   const authenticateError = "Unable to authenticate!"

   if (
      authenticationModel.captchaResponse === undefined ||
      authenticationModel.captchaResponse === null ||
      authenticationModel.captchaResponse === ""
   ) {
      if (!(!process.env.NODE_ENV || process.env.NODE_ENV === "development")) {
         response.message = "Please verify you are not a robot!"
         return response
      }
   }

   response.message = authenticateError

   if (authenticationModel == null) return response

   if (
      authenticationModel.username == null ||
      authenticationModel.password == null
   )
      return response

   if (
      authenticationModel.username.length < 4 ||
      authenticationModel.username.length > 30 ||
      authenticationModel.password.length < 6 ||
      authenticationModel.password.length > 30
   )
      return response

   try {
      const res = await Axios.post(
         type === 1 ? apiUrls.authenticate : adminApiUrls.login,
         authenticationModel
      )

      if (res.status === 200) {
         return res.data
      }

      return response
   } catch (e) {
      return response
   }
}

export async function isAuthorized() {
   try {
      const authHeader = getAuthorizationHeader()

      if (Object.keys(authHeader).length === 0) return false

      const response = await Axios.get(apiUrls.authorize, {
         headers: authHeader,
      })

      if (response.status === 200) return true
   } catch (e) {
      Log(e)
   }

   return false
}
