import React, { Component } from "react"
import { Row, Col, Input, Button, Typography, InputNumber, Switch } from "antd"
import { Table } from "ant-table-extensions"
import {
   addExam,
   getAllExam,
} from "../../../controllers/ExamManagementController"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import "../Dashboard.css"
import { examViewColumns } from "../../../helpers/Columns"
import { Permissions } from "../../../helpers/Permissions"

const { Title } = Typography
const { TextArea } = Input

export default class ExamManagementView extends Component {
   constructor(props) {
      super(props)

      this.state = {
         newExam: {
            id: 0,
            durationInSec: 0,
            name: "",
            allowDemo: false,
            description: "",
            allowAllStudents: true,
            testInformation: "",
            instructions: "",
            passingScore: 0,
            maxQuestions: 10,
            isForeign: false,
            correlationId: "",
            IsExamCert: false,
            teachers: "",
            examType: 0,
            isReady: false,
         },
         exams: [],
         updateExam: [],
      }

      this.handleExamInput = this.handleExamInput.bind(this)
      this.handleExamUpdateInput = this.handleExamUpdateInput.bind(this)
   }

   async componentDidMount() {
      const exams = await getAllExam()
      this.setState({
         exams: exams.data.map((e) => {
            e.updated = {
               id: e.id,
               durationInSec: e.durationInSec,
               name: e.name,
               allowDemo: e.allowDemo,
               description: e.description,
               testInformation: e.testInformation,
               instructions: e.instructions,
               passingScore: e.passingScore,
               maxQuestions: 10,
               isForeign: e.isForeign,
               correlationId: e.correlationId,
               IsExamCert: e.IsExamCert,
               teachers: e.teachers,
               examType: e.examType,
               isReady: e.isReady,
            }

            e.newSection = {
               id: 0,
               examId: e.id,
               name: "",
            }
            e.questions = e.sections.reduce((a, b) => a.concat(b.questions), [])

            e.showAllowedStudents = false
            e.showImportQuestions = false

            return e
         }),
      })
   }

   handleExamInput(name, value) {
      this.setState({
         newExam: {
            ...this.state.newExam,
            [name]: value,
         },
      })
   }

   handleExamUpdateInput(id, name, value) {
      this.setState({
         exams: this.state.exams.map((e) => {
            if (e.id === id) {
               e.updated = { ...e.updated, [name]: value }
            }
            return e
         }),
      })
   }

   render() {
      return (
         <div className='overflow-auto'>
            <div className='m-5'>
               <Title className='main-title mb-5' level={2}>
                  Exam Management
               </Title>

               <div
                  className={
                     "p-5 mt-5 border div-box rounded mb-5 " +
                     (Permissions.exam.add ? "" : "d-none")
                  }>
                  <Title className='mb-5' level={4}>
                     Create New Exam
                  </Title>
                  <Row gutter={24}>
                     <Col span={8}>
                        <label for='name'>Exam Name:</label>
                        <Input
                           id='name'
                           size='large'
                           value={this.state.newExam.name}
                           name='name'
                           type='text'
                           onChange={(e) =>
                              this.handleExamInput(
                                 e.target.name,
                                 e.target.value
                              )
                           }
                        />
                     </Col>
                     <Col span={4}>
                        <label for='mquestions'>Max Questions</label>
                        <InputNumber
                           id='mquestions'
                           size='large'
                           min={1}
                           value={this.state.newExam.maxQuestions}
                           name='maxQuestions'
                           onChange={(e) =>
                              this.handleExamInput("maxQuestions", e)
                           }
                        />
                     </Col>
                     <Col className='d-flex flex-column' span={6}>
                        <label for='durationInSec'>Duration:</label>
                        <InputNumber
                           className='w-100'
                           size='large'
                           id='durationInSec'
                           value={this.state.newExam.durationInSec / 60}
                           name='durationInSec'
                           min={0}
                           onChange={(e) =>
                              this.handleExamInput("durationInSec", e * 60)
                           }
                        />
                     </Col>
                     <Col className='d-flex flex-column' span={6}>
                        <label for='passingScore'>Passing Score:</label>
                        <InputNumber
                           className='w-100'
                           size='large'
                           value={this.state.newExam.passingScore}
                           id='passingScore'
                           name='passingScore'
                           min={0}
                           onChange={(e) =>
                              this.handleExamInput("passingScore", parseInt(e))
                           }
                        />
                     </Col>
                  </Row>
                  <Row gutter={24} className='mt-5'>
                     <Col span={12}>
                        <label for='instructions'>
                           Enter Exam Instructions:
                        </label>
                        <TextArea
                           className='mr-1'
                           value={this.state.newExam.instructions}
                           id='instructions'
                           name='instructions'
                           rows={6}
                           onChange={(e) =>
                              this.handleExamInput(
                                 e.target.name,
                                 e.target.value
                              )
                           }
                        />
                     </Col>
                     <Col span={12}>
                        <label for='testInformation'>
                           Enter Exam Test Information:
                        </label>
                        <TextArea
                           className='ml-1'
                           value={this.state.newExam.testInformation}
                           id='testInformation'
                           name='testInformation'
                           rows={6}
                           onChange={(e) =>
                              this.handleExamInput(
                                 e.target.name,
                                 e.target.value
                              )
                           }
                        />
                     </Col>
                  </Row>
                  <Row className='mt-5'>
                     <Col span={12}>
                        <div className='mr-3'>
                           <label for='passingScore'>Teachers:</label>
                           <Input
                              className='w-100'
                              size='large'
                              value={this.state.newExam.teachers}
                              id='teachers'
                              name='teachers'
                              onChange={(e) =>
                                 this.handleExamInput(
                                    "teachers",
                                    e.target.value
                                 )
                              }
                           />
                        </div>
                     </Col>
                     <Col span={6}>
                        <div className='ml-3'>
                           <label for='passingScore'>Correlation ID:</label>
                           <Input
                              className='w-100'
                              size='large'
                              value={this.state.newExam.correlationId}
                              id='correlationId'
                              name='correlationId'
                              min={0}
                              onChange={(e) =>
                                 this.handleExamInput(
                                    "correlationId",
                                    e.target.value
                                 )
                              }
                           />
                        </div>
                     </Col>
                     <Col span={6}>
                        <div className='ml-3'>
                           <label for='passingScore'>Exam Type:</label>
                           <InputNumber
                              className='w-100'
                              size='large'
                              value={this.state.newExam.examType}
                              id='examType'
                              name='examType'
                              min={0}
                              onChange={(e) =>
                                 this.handleExamInput("examType", parseInt(e))
                              }
                           />
                        </div>
                     </Col>
                  </Row>

                  <Row gutter={24} className='mt-5'>
                     <Col className='d-flex' span={12}>
                        <div className='d-flex flex-column mr-5'>
                           <label for='allowDemo'>Allow Demo:</label>
                           <Switch
                              className='w-25'
                              size='large'
                              id='allowDemo'
                              name='allowDemo'
                              checked={this.state.newExam.allowDemo}
                              onChange={(e) => {
                                 this.handleExamInput("allowDemo", e)
                              }}
                           />
                        </div>
                        <div className='d-flex flex-column ml-5'>
                           <label for='allowAllStudents'>
                              Allow All Groups:
                           </label>
                           <Switch
                              className='w-25'
                              size='large'
                              id='allowAllStudents'
                              name='allowAllStudents'
                              checked={this.state.newExam.allowAllStudents}
                              onChange={(e) => {
                                 this.handleExamInput("allowAllStudents", e)
                              }}
                           />
                        </div>
                        <div className='d-flex flex-column ml-5'>
                           <label for='allowAllStudents'>Is Exam Cert:</label>
                           <Switch
                              className='w-25'
                              size='large'
                              id='IsExamCert'
                              name='IsExamCert'
                              checked={this.state.newExam.IsExamCert}
                              onChange={(e) => {
                                 this.handleExamInput("IsExamCert", e)
                              }}
                           />
                        </div>
                        <div className='d-flex flex-column ml-5'>
                           <label for='allowAllStudents'>Is Foreign:</label>
                           <Switch
                              className='w-25'
                              size='large'
                              id='isForeign'
                              name='isForeign'
                              checked={this.state.newExam.isForeign}
                              onChange={(e) => {
                                 this.handleExamInput("isForeign", e)
                              }}
                           />
                        </div>

                        <div className='d-flex flex-column ml-5'>
                           <label for='isReady'>Is Ready:</label>
                           <Switch
                              className='w-25'
                              size='large'
                              id='isReady'
                              name='isReady'
                              checked={this.state.newExam.isReady}
                              onChange={(e) => {
                                 this.handleExamInput("isReady", e)
                              }}
                           />
                        </div>
                     </Col>

                     <Col
                        className='d-flex align-items-end justify-content-end'
                        span={12}>
                        <Button
                           size='large'
                           type='primary'
                           onClick={async () => {
                              const response = await addExam(this.state.newExam)

                              if (response.isSuccess) {
                                 this.setState({
                                    exams: [
                                       ...this.state.exams,
                                       {
                                          ...response.data,
                                          updated: {
                                             id: response.data.id,
                                             durationInSec:
                                                response.data.durationInSec,
                                             name: response.data.name,
                                             allowDemo: response.data.allowDemo,
                                             description:
                                                response.data.description,
                                             passingScore:
                                                response.data.passingScore,
                                             maxQuestions: 10,
                                             isForeign: response.data.isForeign,
                                             correlationId:
                                                response.data.correlationId,
                                             IsExamCert:
                                                response.data.IsExamCert,
                                             teachers: response.data.teachers,
                                             examType: response.data.examType,
                                          },
                                          questions:
                                             response.data.sections.reduce(
                                                (a, b) => a.concat(b.questions),
                                                []
                                             ),
                                       },
                                    ],
                                    newExam: {
                                       id: 0,
                                       durationInSec: 0,
                                       name: "",
                                       allowDemo: false,
                                       description: "",
                                       allowAllStudents: true,
                                       maxQuestions: 10,
                                       isForeign: false,
                                       correlationId: "",
                                       IsExamCert: false,
                                       teachers: "",
                                       examType: 0,
                                    },
                                 })
                                 showSuccessNotification("Exam Added")
                              } else
                                 showErrorNotification(response.combinedErrors)
                           }}>
                           Add New Exam
                        </Button>
                     </Col>
                  </Row>
               </div>

               <div className='p-5 mt-5 border div-box rounded mb-5'>
                  <div className='d-flex'>
                     <label className='p-2 mb-5 flex-grow-1'>
                        <Title className='my-auto' level={4}>
                           Exams
                        </Title>
                     </label>
                  </div>
                  <Table
                     searchable
                     columns={examViewColumns}
                     dataSource={this.state.exams}
                  />
               </div>
            </div>
         </div>
      )
   }
}
