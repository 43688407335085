const baseStudentPreFix = "api/student/"
const baseExamManagementPath = "api/exam/management/"

export const apiUrls = {
   authenticate: baseStudentPreFix + "authenticate",
   exams: baseStudentPreFix + "exams",
   startExam: baseStudentPreFix + "start-exam/",
   continueExam: baseStudentPreFix + "exam/continue/",
   submit: baseStudentPreFix + "exam/{id}/submit",
   finishExam: baseStudentPreFix + "exam/finish/",
   result: baseStudentPreFix + "exam/result/",
   authorize: baseStudentPreFix + "authorize",
   sectionedExam: baseStudentPreFix + "sectioned/exam",
   examInfo: baseStudentPreFix + "exam/info/",
   account: {
      me: "/api/user/me",
   },
   exam: {
      taken: baseStudentPreFix + "taken-exams",
   },
   profile: {
      get: baseStudentPreFix + "profile/get-profile",
      updatePassword: baseStudentPreFix + "profile/update-password",
      updateProfile: baseStudentPreFix + "profile/update-profile",
      updateEmail: baseStudentPreFix + "profile/update-email",
      updatePicture: baseStudentPreFix + "profile/update-profile-picture",
   },

   foriegnStudent: {
      magicLink: {
         info: "api/foreign-student/magic-link/info/",
      },
   },
}

export const adminApiUrls = {
   login: "api/user/authenticate",
   authorize: "api/user/authorize",
   addUser: "api/user/management/add",
   updateUser: "api/user/management/update",
   removeUser: "api/user/management/remove",
   statistics: "api/user/management/statistics",
   getUsers: "api/user/management/get",
   users: {
      updateAllowedExams: "api/user/management/update-allowed-exams?userId=",
      import: "api/user/management/import",
      export: "api/user/management/export",
      detail: "api/user/management/get-user-detail",
   },
   group: {
      add: "api/student/groups/add",
      update: "api/student/groups/update",
      delete: "api/student/groups/delete/",
      get: "api/student/groups/",
      all: "api/student/groups/all",
      overview: "api/student/groups/overview",
   },
   apiToken: {
      all: "api/tokens/all",
      delete: "api/tokens/delete/",
      add: "api/tokens/add",
   },
   exam: {
      exportQuestions: baseExamManagementPath + "export/",
      importQuestions: baseExamManagementPath + "import/",
      list: baseExamManagementPath + "exam-list",
      result: baseExamManagementPath + "student/result/",
      all: baseExamManagementPath + "all",
      add: baseExamManagementPath + "add",
      update: baseExamManagementPath + "update",
      delete: baseExamManagementPath + "delete/",
      get: baseExamManagementPath + "get/",
      getAllowedGroups: baseExamManagementPath + "allowed-groups/",
      updateAllowedGroups: baseExamManagementPath + "allowed-groups/update/",
      section: {
         all: baseExamManagementPath + "section/all/",
         add: baseExamManagementPath + "section/add",
         update: baseExamManagementPath + "section/update",
         delete: baseExamManagementPath + "section/delete/",
      },
      questions: {
         all: baseExamManagementPath + "questions/all",
         add: baseExamManagementPath + "questions/add",
         update: baseExamManagementPath + "questions/update",
         delete: baseExamManagementPath + "questions/delete/",
         get: baseExamManagementPath + "questions/",
      },
      answers: {
         all: baseExamManagementPath + "answers/all",
         add: baseExamManagementPath + "answers/add",
         update: baseExamManagementPath + "answers/update",
         delete: baseExamManagementPath + "answers/delete/",
      },
   },
}
