import { userTypes } from "../helpers/UserHelper"

let localUserDataObjectName = "userData"

export function getUserType() {
   if (customAuthorizationToken) return 1
   try {
      const user = getLocalUserData()
      return user.type
   } catch (e) {
      return 0
   }
}

export function isForeignAdmin() {
   try {
      const user = getLocalUserData()
      return user.isForeign && user.type === 2
   } catch (e) {
      return false
   }
}

export function getLocalUserData() {
   try {
      const userData = JSON.parse(localStorage.getItem(localUserDataObjectName))
      return userData
   } catch (e) {
      return null
   }
}

export function setLocalUserData(data) {
   try {
      localStorage.setItem(localUserDataObjectName, JSON.stringify(data))
      return true
   } catch (error) {
      return false
   }
}

export function clearUserData() {
   localStorage.removeItem(localUserDataObjectName)
   return true
}

var customAuthorizationToken = null
export function setCustomAuthorizationToken(token) {
   customAuthorizationToken = token
}

export function getAuthorizationHeader() {
   if (customAuthorizationToken)
      return {
         Authorization: `Bearer ${customAuthorizationToken}`,
      }

   const localUserData = getLocalUserData()

   return localUserData == null
      ? {}
      : {
           Authorization: `Bearer ${localUserData.token}`,
        }
}

export function getUsername() {
   const localUserData = getLocalUserData()

   return localUserData == null ? "" : localUserData.userName
}
