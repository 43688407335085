import { PlusCircleFilled } from "@ant-design/icons"
import {
   Button,
   Col,
   Input,
   InputNumber,
   Row,
   Switch,
   Table,
   Typography,
} from "antd"
import React, { useEffect, useState } from "react"
import {
   addStudentGroup,
   getAllStudentGroups,
} from "../../../controllers/GroupManagementController"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import { adminPaths } from "../../../helpers/Paths"
import history from "../../../history"

const { Title } = Typography

export default function GroupManagementView() {
   useEffect(() => {
      async function load() {
         const response = await getAllStudentGroups()
         if (response.isSuccess) {
            setGroups(response.data)
         } else {
            showErrorNotification(response.combinedErros)
         }
      }

      load()
   }, [])

   const addNewGroup = async () => {
      const response = await addStudentGroup(newGroupModel)
      if (response.isSuccess) {
         setGroups([...groups, response.data])
         showSuccessNotification(response.message)
      } else {
         showErrorNotification(response.combinedErrors)
      }
   }

   const [newGroupModel, setNewGroupModel] = useState({
      name: "",
      isForeign: false,
      groupCorrelationId: "",
      useDestination: 0,
   })
   const [groups, setGroups] = useState([])
   return (
      <div className='overflow-auto'>
         <div className='m-5'>
            <div className='d-flex align-items-center justify-content-between mb-2'>
               <Title className='main-title' level={2}>
                  Group Management
               </Title>
            </div>
            <div className='border div-box rounded p-5'>
               <Title className='mb-5' level={4}>
                  Add Group
               </Title>

               <Row>
                  <Col span={7}>
                     <label for='name'>Group Name:</label>

                     <Input
                        id='firstName'
                        size='large'
                        placeholder='Group Name'
                        type='text'
                        value={newGroupModel.name}
                        onChange={(e) =>
                           setNewGroupModel({
                              ...newGroupModel,
                              name: e.target.value,
                           })
                        }
                     />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                     <div className='flex flex-col'>
                        <label for='groupCorrelationId'>
                           Group Correlation Id:
                        </label>

                        <Input
                           id='groupCorrelationId'
                           size='large'
                           className='w-100'
                           type='text'
                           value={newGroupModel.groupCorrelationId}
                           onChange={(e) =>
                              setNewGroupModel({
                                 ...newGroupModel,
                                 groupCorrelationId: e.target.value,
                              })
                           }
                        />
                     </div>
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                     <div className='flex flex-col'>
                        <label for='groupCorrelationId'>Use Destination:</label>

                        <InputNumber
                           className='w-100'
                           id='useDestination'
                           size='large'
                           type='text'
                           value={newGroupModel.useDestination}
                           onChange={(e) => {
                              setNewGroupModel({
                                 ...newGroupModel,
                                 useDestination: parseInt(e),
                              })
                           }}
                        />
                     </div>
                  </Col>
               </Row>
               <Row className='mt-4 justify-between flex'>
                  <Col span={19} className='items-center flex'>
                     <div className='items-center d-flex flex-row'>
                        <label for='allowDemo' className='m-0'>
                           Is Foreign? &nbsp;
                        </label>
                        <Switch
                           className='w-25'
                           size='large'
                           id='allowDemo'
                           name='allowDemo'
                           checked={newGroupModel.isForeign}
                           onChange={(e) => {
                              setNewGroupModel({
                                 ...newGroupModel,
                                 isForeign: e,
                              })
                           }}
                        />
                     </div>
                  </Col>
                  <Col span={4} className='flex justify-content-end'>
                     <Button
                        onClick={async () => {
                           await addNewGroup()
                        }}
                        size='large'
                        type='primary'
                        icon={<PlusCircleFilled />}>
                        Add
                     </Button>
                  </Col>
                  <Col span={1} />
               </Row>
            </div>

            <div className='border div-box rounded p-5 mt-5'>
               <Title className='mb-5' level={4}>
                  Groups
               </Title>

               <Table
                  dataSource={groups}
                  columns={[
                     {
                        title: "Id",
                        key: "id",
                        dataIndex: "id",
                     },
                     {
                        title: "Name",
                        dataIndex: "name",
                        key: "name",
                     },
                     {
                        title: "Action",
                        key: "action",
                        render: (text, row) => (
                           <>
                              <Button
                                 type='link'
                                 onClick={() => {
                                    history.push(
                                       adminPaths.groupManagement + "/" + row.id
                                    )
                                 }}>
                                 View
                              </Button>
                           </>
                        ),
                     },
                  ]}
               />
            </div>
         </div>
      </div>
   )
}
