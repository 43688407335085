import React, { useEffect, useState } from "react"
import {
   Typography,
   PageHeader,
   Breadcrumb,
   Col,
   Row,
   Button,
   Input,
   Select,
   Tag,
   Switch,
   InputNumber,
} from "antd"
import { EditFilled, DeleteFilled } from "@ant-design/icons"
import history from "../../../history"
import {
   getUserDetail,
   removeUser,
   updateUser,
} from "../../../controllers/AdminController"
import { getExamList } from "../../../controllers/ExamManagementController"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import StudentTakenExam from "./StudentTakenExam"
import EditorAllowedExams from "./Editor/EditorAllowedExams"
import "react-dropdown/style.css"
import { getUserKey } from "../../../helpers/UserHelper"
import SelectStudentGroups from "./Student/SelectStudentGroups"

export default function UserInformation(props) {
   let { type, id } = props.match.params
   const { Title } = Typography
   const { Option } = Select
   const [loading, setLoading] = useState(false)

   const [examList, setExamList] = useState([])

   useEffect(() => {
      const f = async () => {
         const detail = await getUserDetail(getUserKey(type), id)
         if (detail.isSuccess) {
            const user = detail.data
            const x = {
               firstName: user.firstName,
               lastName: user.lastName,
               username: user.username,
               email: user.email,
               type: getUserKey(type),
               password: "",
               removeStudentPolicy: 2,
               dateToRemove: new Date(),
               customData: user.customData,
               id: user.id,
               allowedExams: user.allowedExams,
               groupIds: user.groups?.map((g) => g.id) ?? [],
               groups: user.groups ?? [],
               isForeign: user.isForeign,
               correlationId: user.correlationId,
            }
            setModel(x)
         } else {
            history.goBack()
         }

         const el = await getExamList()
         setExamList(el.data)
      }
      f()
   }, [])
   const [model, setModel] = useState({
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      type: getUserKey(type),
      password: "",
      removeStudentPolicy: 2,
      dateToRemove: new Date(),
      groupIds: [],
      customData: {
         takenExams: [],
      },
      groups: [],
      isForeign: false,
      correlationId: "",
   })

   const after = []

   for (var x = 0; x < 12; x++) {
      after.push({
         label: x + 1 + " Month",
         value: x + 1,
      })
   }

   const options = [
      {
         label: "Student",
         value: 1,
      },
      {
         label: "Admin",
         value: 2,
      },
      {
         label: "User",
         value: 3,
      },
   ]

   const handleInput = function (name, value) {
      setModel({
         ...model,
         [name]: value,
      })
   }

   const updateUserData = async function () {
      setLoading(true)
      const ms = {
         ...model,
         groupIds: model.groups.map((g) => g.id),
      }
      const response = await updateUser(ms)

      setLoading(false)
      if (!response.isSuccess) {
         if (response.errors?.length > 0) {
            let error = ""
            response.errors.forEach((e, k) => {
               error += k + 1 + ". " + e + "\n\n"
            })
            showErrorNotification(error)
         } else if (response.message) {
            showErrorNotification(response.message)
         }
      } else {
         showSuccessNotification(response.message)
      }
   }
   return (
      <div className='h-100 overflow-auto'>
         <div className='m-5'>
            <PageHeader
               onBack={() => history.goBack()}
               className='p-0'
               title='User Information'
            />
            <Breadcrumb className='ml-4 pl-4 mt-1'>
               <Breadcrumb.Item>User Management</Breadcrumb.Item>
               <Breadcrumb.Item>User Information</Breadcrumb.Item>
            </Breadcrumb>
            <div className='border div-box rounded my-5 p-5'>
               <Title className='mb-5' level={4}>
                  Update User
               </Title>
               <Row gutter={24}>
                  <Col span={8}>
                     <label for='firstName'>First Name:</label>
                     <Input
                        id='firstName'
                        size='large'
                        type='text'
                        value={model.firstName}
                        onChange={(e) =>
                           handleInput("firstName", e.target.value)
                        }
                     />
                  </Col>
                  <Col span={8}>
                     <label for='lastName'>Last Name:</label>
                     <Input
                        id='lastName'
                        size='large'
                        type='text'
                        value={model.lastName}
                        onChange={(e) =>
                           handleInput("lastName", e.target.value)
                        }
                     />
                  </Col>
               </Row>
               <Row gutter={24} className='mt-5'>
                  <Col span={8}>
                     <label for='username'>Username:</label>

                     <Input
                        id='username'
                        autocomplete='nope'
                        size='large'
                        maxLength={30}
                        minLength={4}
                        type='text'
                        value={model.username}
                        onChange={(e) =>
                           handleInput("username", e.target.value)
                        }
                     />
                  </Col>
                  <Col span={8}>
                     <label for='passWord'>Password:</label>
                     <form autoComplete='off'>
                        <Input.Password
                           id='passWord'
                           autocomplete='off'
                           size='large'
                           maxLength={30}
                           minLength={6}
                           value={model.password}
                           onChange={(e) =>
                              handleInput("password", e.target.value)
                           }
                        />
                     </form>
                  </Col>
               </Row>
               <Row gutter={24} className='mt-5'>
                  <Col span={8}>
                     <label for='emailInput'>Email:</label>
                     <Input
                        id='emailInput'
                        size='large'
                        type='email'
                        placeholder='Email'
                        value={model.email}
                        onChange={(e) => handleInput("email", e.target.value)}
                     />
                  </Col>
                  <Col span={8}>
                     <label for='type'>Type:</label>
                     <Select
                        className='d-block'
                        id='type'
                        size='large'
                        value={options[model.type - 1].value}
                        onChange={(e) => {
                           handleInput("type", e)
                        }}
                        placeholder='Select an option'>
                        {options.map((o) => {
                           return (
                              <Option key={o.value} value={o.value}>
                                 {o.label}
                              </Option>
                           )
                        })}
                     </Select>
                  </Col>
               </Row>
               <Row className='mt-2'>
                  <Col span={8}>
                     <div className='flex flex-col mr-4'>
                        <label for='emailInput'>Correlation Id:</label>
                        <Input
                           id='correlationId'
                           size='large'
                           className='w-100'
                           placeholder='Correlation Id'
                           value={model.correlationId}
                           onChange={(e) => {
                              handleInput("correlationId", e.target.value)
                           }}
                        />
                     </div>
                  </Col>
                  <Col>
                     <div className='d-flex flex-column ml-4'>
                        <label for='isForeign'>Is Foreign?</label>
                        <Switch
                           className='w-25'
                           size='large'
                           id='isForeign'
                           name='isForeign'
                           checked={model.isForeign}
                           onChange={(e) => {
                              handleInput("isForeign", e)
                           }}
                        />
                     </div>
                  </Col>
               </Row>
               {model.type === 1 ? (
                  <>
                     <Row className='mt-4'>
                        <Col span={24}>
                           <div className='pt-2 pb-2'>
                              {model.groups.map((g) => {
                                 return <Tag>{g.name}</Tag>
                              })}
                           </div>
                        </Col>
                     </Row>
                     <Row className='mt-4'>
                        <Col span={7} className='flex flex-col'>
                           <SelectStudentGroups
                              selectedGroups={model.groupIds}
                              onSelectionFinished={(gs) => {
                                 setModel({
                                    ...model,
                                    groups: gs,
                                 })
                              }}
                           />
                        </Col>
                     </Row>
                  </>
               ) : (
                  <></>
               )}

               <div className='d-flex justify-content-between mt-5 pt-5'>
                  <div className='d-flex'>
                     <Button
                        loading={loading}
                        className='d-flex align-items-center mr-5'
                        icon={<DeleteFilled />}
                        size='large'
                        danger
                        onClick={async () => {
                           const r = await removeUser(model.id, model.type)
                           if (r.isSuccess) {
                              showSuccessNotification(r.message)
                              history.goBack()
                           } else showErrorNotification(r.message ?? "Error")
                        }}>
                        Delete User
                     </Button>

                     <Button
                        loading={loading}
                        className='d-flex align-items-center'
                        icon={<EditFilled />}
                        type='primary'
                        size='large'
                        onClick={async (c) => await updateUserData()}>
                        Update User
                     </Button>
                  </div>
               </div>
            </div>

            {model.type === 1 ? (
               <StudentTakenExam
                  exams={model.customData.takenExams}
                  studentId={model.id}
               />
            ) : model.type === 3 ? (
               <EditorAllowedExams
                  userId={model.id}
                  examList={examList}
                  allowedExams={model.allowedExams}
               />
            ) : (
               <></>
            )}
         </div>
      </div>
   )
}
