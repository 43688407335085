import React from "react"
import { Typography, Descriptions, Button } from "antd"
import {
   ClockCircleOutlined,
   ArrowLeftOutlined,
   ArrowRightOutlined,
} from "@ant-design/icons"

const { Title, Text } = Typography
const TimeLabel = (
   <div className='border remaining-time-div d-flex align-items-center justify-content-center my-auto'>
      <ClockCircleOutlined className='mr-3' />
      88:88
   </div>
)
const QuestionCount = (
   <div className='d-flex justify-content-center'>
      <Title level={5}>2 / 10 Question</Title>
   </div>
)
const NextButton = (
   <div className='d-flex justify-content-center'>
      <Button type='primary' className='d-flex align-items-center'>
         Next
         <ArrowRightOutlined />
      </Button>
   </div>
)
const PreviousButton = (
   <div className='d-flex justify-content-center'>
      <Button className='d-flex align-items-center'>
         <ArrowLeftOutlined />
         Previous
      </Button>
   </div>
)
const FinishButton = (
   <div className='d-flex justify-content-center'>
      <Button type='primary' className='d-flex align-items-center'>
         Finish
         <ArrowRightOutlined />
      </Button>
   </div>
)

export default function Page2() {
   return (
      <div className='d-flex flex-column h-100 w-100 py-5'>
         <Title className='mt-5' level={2}>
            Test Interface
         </Title>
         <Text
            style={{ fontWeight: "500", fontSize: "16px" }}
            className='mt-3 mb-2'>
            The following controls will be available during the test
         </Text>

         <Descriptions className='w-75' size='large' bordered>
            <Descriptions.Item span={3} label={TimeLabel}>
               The Timer displays the remaining test time.
            </Descriptions.Item>
            <Descriptions.Item span={3} label={QuestionCount}>
               The Counter displays the question in which you are and the total
               number of questions of the test.
            </Descriptions.Item>
            <Descriptions.Item span={3} label={NextButton}>
               The Next button takes you to the next question, saving your
               progress.
            </Descriptions.Item>
            <Descriptions.Item span={3} label={PreviousButton}>
               The Previous button takes you to the previous question, saving
               your progress.
            </Descriptions.Item>
            <Descriptions.Item span={3} label={FinishButton}>
               The Finish button will end the test and calculate the results for
               you.
            </Descriptions.Item>
         </Descriptions>
      </div>
   )
}
