import { adminApiUrls } from "../routes"
import { doAuthorizedGetCall, doAuthorizedPostCall } from "./AdminController"

export async function getAllTokens() {
   return (await doAuthorizedGetCall(adminApiUrls.apiToken.all)).data
}

export async function deleteToken(id) {
   return (await doAuthorizedGetCall(adminApiUrls.apiToken.delete + id)).data
}

export async function addNewApiToken(vm) {
   return (await doAuthorizedPostCall(adminApiUrls.apiToken.add, vm)).data
}
