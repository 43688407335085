import axios from "axios"
import { adminApiUrls } from "../routes"
import { getAuthorizationHeader } from "./Authorization"

export async function isAdminAuthorized() {
   try {
      const authHeader = getAuthorizationHeader()
      if (Object.keys(authHeader).length === 0) return false

      const response = await axios.get(adminApiUrls.authorize, {
         headers: authHeader,
      })

      if (response.status === 200) return true
   } catch (e) {
      return false
   }
}

export async function getUserDetail(userType, userId) {
   const response = await doAuthorizedGetCall(
      adminApiUrls.users.detail + "?userId=" + userId + "&userType=" + userType
   )
   return response.data
}

export async function getUsers() {
   return (await doAuthorizedGetCall(adminApiUrls.getUsers)).data
}

export async function updateUser(model) {
   return (await doAuthorizedPostCall(adminApiUrls.updateUser, model)).data
}

export async function removeUser(userId, userType) {
   return (
      await doAuthorizedGetCall(
         adminApiUrls.removeUser + "?userId=" + userId + "&userType=" + userType
      )
   ).data
}

export async function addUser(model) {
   const response = (await doAuthorizedPostCall(adminApiUrls.addUser, model))
      .data
   return response
}

export async function doAuthorizedGetCall(api) {
   return await axios.get(api, {
      headers: getAuthorizationHeader(),
   })
}

export async function doAuthorizedPostCall(api, data) {
   return await axios.post(api, data, {
      headers: getAuthorizationHeader(),
   })
}

export async function getStatistics() {
   return (await doAuthorizedGetCall(adminApiUrls.statistics)).data
}

export async function updateAllowedExam(exams, userId) {
   return (
      await doAuthorizedPostCall(
         adminApiUrls.users.updateAllowedExams + userId,
         exams
      )
   ).data
}

export async function importUsers(users) {
   return (await doAuthorizedPostCall(adminApiUrls.users.import, users)).data
}

export async function exportUsers() {
   return (await doAuthorizedGetCall(adminApiUrls.users.export)).data
}
