import {
   Row,
   Col,
   Typography,
   Button,
   Divider,
   Image,
   Input,
   Select,
   Upload,
   Alert,
   Modal,
} from "antd"
import { EditTwoTone } from "@ant-design/icons"
import { InputGroup, Label } from "reactstrap"
import React, { useState } from "react"
import { addQuestion } from "../../../controllers/ExamManagementController"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import EditQuestion from "./EditQuestion"
const { Search } = Input

const { Title, Text } = Typography

export default function Questions({
   sectionId,
   examId,
   sectionQuestions,
   ...props
}) {
   const [newQuestion, setNewQuestion] = useState({
      id: 0,
      text: "",
      examId: examId,
      sectionId: sectionId,
      answerType: 1,
      imageUrl: "",
      points: 1,
   })
   const [questions, setQuestions] = useState(
      sectionQuestions.map((q) => {
         q.showEditQuestion = false
         q.visble = true

         return q
      })
   )

   return (
      <>
         <div className='px-5'>
            <div className='my-5'>
               <Row gutter={24}>
                  <Col span={16}>
                     <Input
                        size='large'
                        value={newQuestion.text}
                        prefix={
                           <label className='m-0 opacity-50'>
                              Question text:{" "}
                           </label>
                        }
                        onChange={(e) => {
                           setNewQuestion({
                              ...newQuestion,
                              text: e.target.value,
                           })
                        }}
                     />
                  </Col>
                  <Col span={4}>
                     <Input
                        size='large'
                        type='number'
                        prefix={
                           <label className='m-0 opacity-50'>Points: </label>
                        }
                        min='1'
                        value={newQuestion.points}
                        placeholder='Points'
                        onChange={(e) => {
                           setNewQuestion({
                              ...newQuestion,
                              points: e.target.value,
                           })
                        }}
                     />
                  </Col>
                  <Col span={4}>
                     <Select
                        className='w-100'
                        size='large'
                        defaultValue='1'
                        onChange={(v) =>
                           setNewQuestion({
                              ...newQuestion,
                              answerType: parseInt(v),
                           })
                        }>
                        <Select.Option value='1'>Single</Select.Option>
                        <Select.Option value='2'>Multiple</Select.Option>
                     </Select>
                  </Col>
               </Row>

               <div className='d-flex align-items-center mt-5'>
                  <div className='flex-grow-1'>
                     <Image width={100} src={newQuestion.imageUrl} />
                  </div>
                  <Upload
                     fileList={[]}
                     accept='image/png'
                     customRequest={(file, url) => {}}
                     onChange={(info) => {
                        const reader = new FileReader()
                        reader.addEventListener("load", () =>
                           setNewQuestion({
                              ...newQuestion,
                              imageUrl: reader.result,
                           })
                        )
                        reader.readAsDataURL(info.file.originFileObj)
                     }}>
                     <Button size='large' type='dashed'>
                        Upload Image
                     </Button>
                  </Upload>
                  <Button
                     className='ml-5'
                     size='large'
                     type='primary'
                     onClick={async () => {
                        const response = await addQuestion(newQuestion)
                        if (response.isSuccess) {
                           showSuccessNotification("Question Added")
                           setQuestions([
                              ...questions,
                              {
                                 ...response.data,
                                 updated: {
                                    id: response.data.id,
                                    text: response.data.text,
                                    examId: examId,
                                    sectionId: sectionId,
                                    answerType: response.data.answerType,
                                    imageUrl: "",
                                    points: response.data.points,
                                 },
                                 showEditQuestion: false,
                                 visble: true,
                              },
                           ])
                        } else showErrorNotification("Unable to add question")
                     }}>
                     Add New Question
                  </Button>
               </div>
            </div>

            <Divider className='pb-3' orientation='left'>
               Questions List
            </Divider>
            <div>
               <Search
                  placeholder='Search'
                  size='large'
                  onSearch={(value) => {
                     setQuestions(
                        questions.map((s) => {
                           if (
                              s.text.toLowerCase().includes(value.toLowerCase())
                           ) {
                              return {
                                 ...s,
                                 visble: true,
                              }
                           }

                           return {
                              ...s,
                              visble: false,
                           }
                        })
                     )
                  }}
               />
            </div>

            {questions.map((q, k) => {
               if (!q.visble) return <></>
               return (
                  <div
                     key={"q" + q.id}
                     className='p-5 my-5 border div-box rounded'>
                     <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                           <Title className='mb-0 mr-3' level={5}>
                              Question {k + 1}
                           </Title>
                           <Text>Points ({q.points})</Text>
                        </div>
                        <div>
                           <Button
                              onClick={() => {
                                 setQuestions(
                                    questions.map((qs) => {
                                       if (qs.id === q.id) {
                                          return {
                                             ...qs,
                                             showEditQuestion: true,
                                          }
                                       }
                                       return qs
                                    })
                                 )
                              }}
                              className='d-flex align-items-center'
                              type='text'
                              icon={<EditTwoTone />}>
                              Edit Question
                           </Button>
                           <Modal
                              title='Edit Question'
                              style={{ top: 50, left: 100 }}
                              width={1000}
                              visible={q.showEditQuestion}
                              onCancel={() => {
                                 setQuestions(
                                    questions.map((qs) => {
                                       if (qs.id === q.id) {
                                          return {
                                             ...qs,
                                             showEditQuestion: false,
                                          }
                                       }
                                       return qs
                                    })
                                 )
                              }}
                              footer={null}>
                              <EditQuestion
                                 sectionId={q.sectionId}
                                 examId={examId}
                                 question={q}
                                 onQuestionDeleted={() => {
                                    setQuestions(
                                       questions.filter((qs) => qs.id !== q.id)
                                    )
                                 }}
                                 onQuestionUpdated={(q) => {
                                    setQuestions(
                                       questions.map((qs) => {
                                          if (qs.id === q.id) {
                                             return {
                                                ...qs,
                                                ...q,
                                                showEditQuestion: false,
                                             }
                                          }
                                          return qs
                                       })
                                    )
                                 }}
                              />
                           </Modal>
                        </div>
                     </div>
                     <div className='d-flex'>
                        <div className='flex-grow-1'>
                           <div className='mt-3'>
                              <Text style={{ fontSize: "16px" }}>{q.text}</Text>
                           </div>
                           <div className='mt-5'>
                              {q.answers.map((a) => {
                                 return (
                                    <InputGroup
                                       className='d-flex flex-column mb-3 p-4 w-50 bg-white rounded'
                                       key={`s-q-a-${a.id}-${q.id}-${a.id}`}>
                                       <form
                                          id={k + "f-i"}
                                          className='d-flex align-items-center m-0'>
                                          <input
                                             id={k + "i"}
                                             type={
                                                q.answerType === 1
                                                   ? "radio"
                                                   : "checkbox"
                                             }
                                             name='answers'
                                             value={a.id}
                                             checked={a.isCorrect}
                                          />
                                          <Label
                                             className='ml-3'
                                             check
                                             for={k + "i"}>
                                             {a.text}
                                          </Label>
                                       </form>
                                       <div className='mt-2'>
                                          <Alert
                                             type='success'
                                             message={a.rightDescription}
                                          />
                                          <Alert
                                             className='mt-2'
                                             type='error'
                                             message={a.wrongDescription}
                                          />
                                       </div>
                                    </InputGroup>
                                 )
                              })}
                           </div>
                        </div>
                        <div className='mt-5'>
                           <Image width={200} src={q.imageUrl} />
                        </div>
                     </div>
                  </div>
               )
            })}
         </div>
      </>
   )
}
