import { Button, Modal, Upload } from "antd"
import React, { useState } from "react"
import { UploadOutlined } from "@ant-design/icons"
import Papa from "papaparse"
import { importUsers } from "../../../controllers/AdminController"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"

export default function ImportUsers({ show, onClose, onImport, ...props }) {
   const [ready, setReady] = useState(false)
   const [rawUsers, setRawUsers] = useState({
      csv: "",
   })

   return (
      <Modal
         okText='Import'
         visible={show}
         okButtonProps={{ disabled: !ready }}
         title='Import Users'
         onOk={async () => {
            const res = await importUsers(rawUsers)
            if (res.isSuccess) {
               showSuccessNotification(res.message)
               if (onImport) onImport()
               onClose()
            } else {
               showErrorNotification(res.combinedErrors)
            }
            setReady(false)
         }}
         onCancel={() => {
            if (onClose !== undefined) onClose()
         }}>
         <div className='text-center w-100 m-2'>
            <Upload
               maxCount={1}
               accept='.csv'
               showUploadList={false}
               beforeUpload={(file) => {
                  const reader = new FileReader()

                  reader.onload = async (e) => {
                     setRawUsers({
                        csv: e.target.result.trim(),
                     })
                     setReady(true)
                  }
                  reader.readAsText(file)

                  // Prevent upload
                  return false
               }}
               {...props}>
               <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <div>{ready ? "Ready To Import" : "Select a CSV file"}</div>
         </div>
      </Modal>
   )
}
