import React, { Component } from "react"
import { getExamResult } from "../controllers/ExamController"
import { FormGroup, Container } from "react-bootstrap"
import {
   Button,
   PageHeader,
   Statistic,
   Typography,
   Row,
   Col,
   Collapse,
   Alert,
} from "antd"
import { InputGroup, Label } from "reactstrap"
import { PieChart } from "react-minimal-pie-chart"
import "./report.css"
import history from "../history"
import { jsPDF } from "jspdf"
import domtoimage from "dom-to-image"
import { getStudentResult } from "../controllers/ExamManagementController"

var randomColor = require("randomcolor")
const { Title, Text } = Typography
const { Panel } = Collapse
const moment = require("moment")

class Report extends Component {
   constructor(props) {
      super(props)

      this.state = {
         result: null,
         generatingReport: false,
         generatedReport: null,
         imageHeight: 0,
         imageWidth: 0,
         type: 1,
      }

      this.getSections = this.getSections.bind(this)
      this.finishedGeneratingReport = this.finishedGeneratingReport.bind(this)
   }

   async componentDidMount() {
      var id

      if (this.props.examId === undefined || this.props.examId === null) {
         id = this.props.match.params.id

         if (id === "demo") {
            if (this.props.location.results) {
               localStorage.setItem(
                  "examresult",
                  JSON.stringify(this.props.location.results)
               )

               await this.setState({
                  result: this.props.location.results,
               })
            } else {
               const res = localStorage.getItem("examresult")
               if (res === null) history.push("/")

               await this.setState({
                  result: JSON.parse(res),
               })
            }
         } else {
            const result = await getExamResult(id)

            await this.setState({
               result: result.data,
            })
         }
      } else {
         id = this.props.examId
         if (!this.props.admin) {
            const result = await getExamResult(id)

            await this.setState({
               result: result.data,
            })
         } else {
            const sid = this.props.studentId

            const result = await getStudentResult(sid, id)

            await this.setState({
               result: result.data,
               type: 2,
            })
         }
      }

      const rc = randomColor({
         luminosity: "dark",
         count: this.state.result.sections.length,
      })
      this.setState({
         result: {
            ...this.state.result,
            sections: this.state.result.sections.map((s, i) => {
               return { ...s, color: rc[i] }
            }),
         },
      })
   }

   getSections() {
      if (!this.state.result) return <></>

      return this.state.result.sections.map((s, i) => {
         var attr = {}
         if (!this.state.generatingReport) {
            attr = {
               defaultActiveKey: i + 1,
            }
         } else attr = { activeKey: 0 }
         return (
            <Collapse
               {...attr}
               expandIconPosition='right'
               key={"collapse-" + i}
               className='font-16 mb-3'>
               <Panel
                  header={
                     <Title level={4} className='d-inline p-0'>
                        {s.name}
                     </Title>
                  }
                  key={1}
                  extra={
                     <p className='m-0'>
                        Score:{" "}
                        <span className='font-weight-bold'>
                           {s.takenScore + " / " + s.totalScore}
                        </span>
                     </p>
                  }>
                  {this.state.generatingReport ? (
                     <></>
                  ) : (
                     <div>
                        {s.questions.map((q, i) => {
                           return (
                              <div
                                 className='bg-light p-5 border mb-4'
                                 key={"q-s-" + s.id + "-" + q.id}>
                                 <div className='d-flex justify-content-between'>
                                    <Title level={5}>Question {i + 1} </Title>
                                    <div>
                                       {q.takenScore} / {q.points}
                                    </div>
                                 </div>
                                 <div>{q.text}</div>
                                 {this.state.generatingReport ? (
                                    <></>
                                 ) : (
                                    <div className='mt-5'>
                                       {q.answers.map((a) => {
                                          return (
                                             <InputGroup
                                                className='d-flex flex-column mb-3 p-4 w-50 bg-white rounded'
                                                key={`s-q-a-${s.id}-${q.id}-${a.id}`}>
                                                <FormGroup className='d-flex align-items-center m-0'>
                                                   <input
                                                      id={i + "i"}
                                                      type={
                                                         q.answerType === 1
                                                            ? "radio"
                                                            : "checkbox"
                                                      }
                                                      name='answers'
                                                      value={a.id}
                                                      checked={q.selectedAnswers?.includes(
                                                         a.id
                                                      )}
                                                   />
                                                   <Label
                                                      className='ml-3'
                                                      check
                                                      for={i + "i"}>
                                                      {a.text}
                                                   </Label>
                                                </FormGroup>
                                                <div className='mt-2'>
                                                   {q.selectedAnswers?.includes(
                                                      a.id
                                                   ) ? (
                                                      a.isCorrect ? (
                                                         <Alert
                                                            showIcon
                                                            type='success'
                                                            message={
                                                               a.rightDescription
                                                            }
                                                         />
                                                      ) : (
                                                         <Alert
                                                            showIcon
                                                            type='error'
                                                            message={
                                                               a.wrongDescription
                                                            }
                                                         />
                                                      )
                                                   ) : null}
                                                </div>
                                             </InputGroup>
                                          )
                                       })}
                                    </div>
                                 )}
                              </div>
                           )
                        })}
                     </div>
                  )}
               </Panel>
            </Collapse>
         )
      })
   }

   finishedGeneratingReport() {
      this.setState({
         generatingReport: false,
      })
   }

   convertPhotoImageToPdf(imgPath) {
      var doc = new jsPDF(undefined, null, null, true)
      doc.addImage(imgPath, "PNG", 10, 10, 0, 0, null, "FAST", 0)
      //if want add one page
      //doc.addPage()
      doc.save("testPdf.pdf")
   }

   render() {
      if (!this.state.result) return <></>

      const sectionResult = []

      this.state.result.sections.forEach((s) => {
         let sectionTotal = 0
         let sectionObtained = 0
         s.questions.forEach((q) => {
            q.answers.forEach((a) => {
               sectionTotal += a.points

               if (a.isCorrect && q?.selectedAnswers?.includes(a.id))
                  sectionObtained += a.points
            })
         })

         sectionResult.push({
            total: sectionTotal,
            obtained: sectionObtained,
            name: s.name,
         })
      })
      return (
         <>
            <div
               className={
                  "bg-white d-flex flex-column " +
                  (this.state.generatingReport ? "report-container" : "w-100")
               }
               id='report'>
               <div className='exam-navbar sticky-top'>
                  <Container className='d-flex justify-content-between align-items-center'>
                     <PageHeader
                        className='px-0'
                        {...{
                           onBack:
                              !this.state.generatingReport && !this.props.admin
                                 ? () => {
                                      history.push("/")
                                   }
                                 : null,
                        }}
                        title={this.state.result?.name}
                        subTitle={"Final Report"}
                     />
                     <div className='d-flex align-items-center justify-content-center mr-5'>
                        <Text
                           style={{ fontSize: "24px" }}
                           className='mr-5 text-white'>
                           Score:
                        </Text>
                        <Statistic
                           value={this.state.result.takenScore}
                           suffix={" / " + this.state.result.totalScore}
                        />
                     </div>
                     <div className='d-flex align-items-center justify-content-center ml-5'>
                        <Button
                           className={
                              this.state.generatingReport ? "d-none" : ""
                           }
                           onClick={async () => {
                              await this.setState({
                                 generatingReport: true,
                              })

                              setTimeout(async () => {
                                 const scale = 2
                                 var elm = document.getElementById("report")
                                 var ih = elm.offsetHeight * scale
                                 var iw = elm.offsetWidth * scale

                                 const dataUrl = await domtoimage.toPng(elm, {
                                    height: ih,
                                    style: {
                                       transform: `scale(${scale}) translate(${
                                          elm.offsetWidth / 2 / scale
                                       }px, ${elm.offsetHeight / 2 / scale}px)`,
                                    },
                                    width: iw,
                                 })

                                 this.setState({
                                    generatedReport: dataUrl,
                                    generatingReport: false,
                                    imageHeight: ih,
                                    imageWidth: iw,
                                 })

                                 var doc = new jsPDF({
                                    orientation: "l",
                                    unit: "px",
                                    format: [iw, ih],
                                 })

                                 var ph = doc.internal.pageSize.height
                                 var pw = doc.internal.pageSize.width

                                 doc.addImage(dataUrl, "PNG", 0, 0, pw, ph)
                                 doc.save("result.pdf")
                              }, 1000)
                           }}>
                           Export
                        </Button>
                     </div>
                  </Container>
               </div>
               <Container>
                  <Row
                     gutter={32}
                     justify='space-between'
                     className='my-5 py-4'>
                     <Col span={12}>
                        <div className='d-flex flex-column rounded report-div h-100'>
                           <Title level={3} className='mb-5'>
                              Summary
                           </Title>
                           <Text className='report-text mb-1'>
                              Exam Name: &nbsp;
                              <span className='font-weight-bold'>
                                 {this.state.result.name}
                              </span>
                           </Text>
                           <Text className='report-text mb-1'>
                              Student Name: &nbsp;
                              <span className='font-weight-bold'>
                                 {this.state.result.studentName}
                              </span>
                           </Text>
                           <Text className='report-text mb-1'>
                              Date tested: &nbsp;
                              <span className='font-weight-bold'>
                                 {this.state.result.date?.split("T")[0] ??
                                    new Date().toLocaleDateString("en-US")}
                              </span>
                           </Text>
                           <Text className='report-text mb-1'>
                              Student Id: &nbsp;
                              <span className='font-weight-bold'>
                                 {this.state.result.studentId}
                              </span>
                           </Text>
                           <Text className='report-text mb-1'>
                              Result: &nbsp;
                              <span className='font-weight-bold'>
                                 {this.state.result.result}
                              </span>
                           </Text>
                           <Text className='report-text mb-1'>
                              Teachers: &nbsp;
                              <span className='font-weight-bold'>
                                 {this.state.result.teachers}
                              </span>
                           </Text>
                           {/* <Text className='report-text mb-1'>
                              Total Time: &nbsp;
                              <span className='font-weight-bold'>00:00</span>
                           </Text>
                           <Text className='report-text mb-1'>
                              Number of Questions: &nbsp;
                              <span className='font-weight-bold'>
                                 {this.state.result.sections.length}
                              </span>
                           </Text> */}
                        </div>
                     </Col>
                     <Col span={12}>
                        <div className='d-flex justify-content-between rounded report-div h-100'>
                           <div className='d-flex flex-column'>
                              <Title level={3} className='mb-5'>
                                 Total Score
                              </Title>
                              <Text className='report-text mb-1'>
                                 Final Score: &nbsp;
                                 <span className='font-weight-bold'>
                                    {this.state.result.takenScore} /{" "}
                                    {this.state.result.totalScore}
                                 </span>
                              </Text>
                              {this.state.result.sections.map((a, i) => {
                                 return (
                                    <Text className='report-text mb-1'>
                                       <span
                                          className='font-weight-bold'
                                          style={{
                                             color: a.color,
                                          }}>
                                          {a.name} ( {a.takenScore} /{" "}
                                          {a.totalScore} ){" "}
                                       </span>{" "}
                                    </Text>
                                 )
                              })}
                           </div>
                           <PieChart
                              style={{ width: "150px" }}
                              data={this.state.result.sections.map((s) => {
                                 return {
                                    title: s.name,
                                    value: s.takenScore,
                                    color: s.color,
                                 }
                              })}
                           />
                        </div>
                     </Col>
                  </Row>
                  <div className='d-flex flex-column w-100 mt-5 mb-5'>
                     {this.getSections()}
                  </div>
               </Container>
               {this.state.generatingReport ? (
                  <div style={{ height: "100px" }}></div>
               ) : (
                  <></>
               )}
            </div>
         </>
      )
   }
}

export default Report
