import React from "react"
import { Modal } from "antd"
import Report from "../../Report"

export default function ViewStudentResult({
   show,
   examId,
   onClose,
   studentId,
   from,
   ...props
}) {
   return (
      <Modal
         title='Result'
         className='w-100'
         style={{ zIndex: "9999", top: "0px" }}
         visible={show}
         onCancel={() => {
            if (onClose !== null) onClose()
         }}
         footer={null}>
         <Report
            examId={examId}
            admin={from === "admin"}
            studentId={studentId}
         />
      </Modal>
   )
}
