import React, { useEffect, useState } from "react"
import { getStatistics } from "../../controllers/AdminController"
import "./Dashboard.css"
import { Typography, Card, Col, Row } from "antd"

const { Meta } = Card
const { Title } = Typography

export default function Dashboard() {
   const [data, setData] = useState(null)

   useEffect(() => {
      const load = async () => {
         const response = await getStatistics()

         setData(response.data)
      }

      load()
   }, [])

   const height = "220px"

   const render = () => {
      if (data == null) return <></>

      return (
         <div className='m-5 bg-white text-center h-100 overflow-auto'>
            <Title className='main-title mb-5' level={2}>
               Main DashBoard
            </Title>
            <Row className='mt-5'>
               <Col span={8} className='pr-3 pl-3'>
                  <Card
                     style={{
                        backgroundColor: "#FFEFD3",
                        borderRadius: 5,
                        borderWidth: 2,
                        borderColor: "#FFA400",
                        height: height,
                     }}
                     hoverable>
                     <h1 style={{ fontSize: "60px", color: "#FFA400" }}>
                        {data.users.filter((t) => t.type === 2)[0]?.count ?? 0}
                     </h1>
                     <Meta
                        title={"Number of admins"}
                        description='Manage Users, Manage Exams'
                     />
                  </Card>
               </Col>
               <Col span={8} className='pr-3 pl-3'>
                  <Card
                     style={{
                        backgroundColor: "#FFE3DF",
                        borderRadius: 5,
                        borderWidth: 2,
                        borderColor: "#FF715B",
                        height: height,
                     }}
                     hoverable>
                     <h1 style={{ fontSize: "60px", color: "#FF715B" }}>
                        {data.users.filter((t) => t.type === 3)[0]?.count ?? 0}
                     </h1>
                     <Meta
                        title={"Number of editors"}
                        description={"Manage Exams"}
                     />
                  </Card>
               </Col>
               <Col span={8} className='pr-3 pl-3'>
                  <Card
                     style={{
                        backgroundColor: "#E1FFF2",
                        borderRadius: 5,
                        borderWidth: 2,
                        borderColor: "#00A45A",
                        height: height,
                     }}
                     hoverable>
                     <h1 style={{ fontSize: "60px", color: "#00A45A" }}>
                        {data.students}
                     </h1>
                     <Meta
                        title={"Number of students"}
                        description={"Take Exams"}
                     />
                  </Card>
               </Col>
            </Row>

            <Row className='mt-4'>
               <Col span={8} className='pr-3 pl-3'>
                  <Card
                     style={{
                        backgroundColor: "#F2E4FF",
                        borderRadius: 5,
                        borderWidth: 2,
                        borderColor: "#8500FF",
                     }}
                     hoverable>
                     <div className='d-flex align-items-center'>
                        <h1
                           style={{ fontSize: "60px", color: "#8500FF" }}
                           className='m-0 pl-3'>
                           {data.exam.exams}
                        </h1>
                        <Meta
                           className='flex-grow-1 text-center'
                           title={"Exams"}
                           description='Number Of Exams'
                        />
                     </div>
                  </Card>
               </Col>
               <Col span={8} className='pr-3 pl-3'>
                  <Card
                     style={{
                        backgroundColor: "#bae7ff",
                        borderRadius: 5,
                        borderWidth: 2,
                        borderColor: "#1890ff",
                     }}
                     hoverable>
                     <div className='d-flex align-items-center'>
                        <h1
                           style={{ fontSize: "60px", color: "#1890ff" }}
                           className='pl-3 m-0'>
                           {data.exam.sections}
                        </h1>
                        <Meta
                           className='flex-grow-1 text-center'
                           title={"Sections"}
                           description='Number Of Sections'
                        />
                     </div>
                  </Card>
               </Col>
               <Col span={8} className='pr-3 pl-3'>
                  <Card
                     style={{
                        backgroundColor: "#ffd6e7",
                        borderRadius: 5,
                        borderWidth: 2,
                        borderColor: "#eb2f96",
                     }}
                     hoverable>
                     <div className='d-flex align-items-center'>
                        <h1
                           style={{ fontSize: "60px", color: "#eb2f96" }}
                           className='pl-3 m-0'>
                           {data.exam.questions}
                        </h1>
                        <Meta
                           className='flex-grow-1 text-center'
                           title={"Questions"}
                           description='Number Of Questions'
                        />
                     </div>
                  </Card>
               </Col>
            </Row>
         </div>
      )
   }

   return render()
}
