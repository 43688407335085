import React, { Component } from "react"
import { Route, Redirect } from "react-router-dom"
import { isAuthorized } from "../controllers/AccountController"
import { adminPaths, studentRoutes } from "./Paths"

export default class UnAuthorizedRoute extends Component {
   constructor(props) {
      super(props)

      this.state = {
         isAuthorized: false,
         isAuthorizing: true,
      }
   }

   componentDidMount() {
      isAuthorized().then((result) => {
         this.setState({
            isAuthorized: result,
            isAuthorizing: false,
         })
      })
   }

   render() {
      if (this.state.isAuthorizing) return <div></div>

      if (this.state.isAuthorized) {
         return (
            <Redirect
               to={
                  this.props.type >= 2
                     ? adminPaths.dashboard
                     : studentRoutes.dashboard
               }
            />
         )
      }

      return (
         <Route
            {...this.props}
            render={(props) => {
               return <this.props.component {...props} />
            }}
         />
      )
   }
}
