export const preStudentPath = "/student/"
export const preAdminPath = "/admin/"

export const studentRoutes = {
   login: preStudentPath + "login",
   dashboard: preStudentPath + "dashboard",
   instructions: preStudentPath + "exam/instructions",
   report: preStudentPath + "exam/report/:id",
   exam: preStudentPath + "exam/:id",
   profile: preStudentPath + "profile",
   results: preStudentPath + "results",
}

export function getExamPath(id) {
   return "/student/exam/" + id
}

export function getDemoPath() {
   return "/student/exam/demo"
}

export function getReportPath(id) {
   return "/student/exam/report/" + id
}

export const adminPaths = {
   login: preAdminPath + "login",
   dashboard: preAdminPath + "dashboard",
   userManagement: preAdminPath + "dashboard/user-management",
   examManagement: preAdminPath + "dashboard/exam-management",
   groupManagement: preAdminPath + "dashboard/group-management",
   tokenManagement: preAdminPath + "dashboard/api-token-management",
}
