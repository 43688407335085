export const answerType = {
   multiple: 2,
   single: 1,
}

export const userTypes = {
   student: 1,
   admin: 2,
   user: 3,
}
