import { errorResponse } from "../../helpers/ServerResponseModel"
import { apiUrls } from "../../routes"

export async function getMagicLinkInfo(id) {
   try {
      const response = await fetch(
         `${apiUrls.foriegnStudent.magicLink.info}${id}`
      )
      const data = await response.json()
      return data
   } catch (e) {
      return errorResponse("Error in getting magic link info")
   }
}
