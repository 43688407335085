import React, { useState } from "react"
import { useEffect } from "react"
import { getSelf } from "../controllers/AccountController"

export default function useUser() {
   const [user, setUser] = useState(null)

   useEffect(() => {
      const load = async () => {
         const response = await getSelf()
         if (response.isSuccess) {
            setUser(response.data)
         } else {
         }
      }

      load()
   }, [])
   return user
}
