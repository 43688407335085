import React from "react"
import { Typography, Descriptions, Radio, Checkbox } from "antd"

const { Title, Text } = Typography
const InputChecked = (
   <div className='d-flex justify-content-center'>
      <Radio checked />
      <Checkbox checked />
   </div>
)
const InputUnChecked = (
   <div className='d-flex justify-content-center'>
      <Radio checked={false} />
      <Checkbox checked={false} />
   </div>
)

export default function Page3() {
   return (
      <div className='f-flex flex-column py-5 my-5'>
         <Title level={2} className='mb-5'>
            Question List and Test Final Report
         </Title>
         <div className='w-50 mb-5 d-flex flex-column'>
            <Text className='mb-3'>
               Before completing the test, remember you will be able to review
               the Question List. This list will show if you did or did NOT
               answer each question, or if you marked any of them for further
               review.
            </Text>
            <Text
               style={{ fontWeight: "500", fontSize: "16px" }}
               className='mt-3'>
               The following icons will show the status mentioned above:
            </Text>
         </div>

         <Descriptions size='large' bordered className='w-75'>
            <Descriptions.Item span={3} label={InputChecked}>
               The question has been answered or any steps have been performed.
               Whenever you click the Next button, the question will be saved as
               Answered.
            </Descriptions.Item>
            <Descriptions.Item span={3} label={InputUnChecked}>
               The question has NOT been answered nor have any step been
               performed.
            </Descriptions.Item>
         </Descriptions>

         <div className='w-50 mt-5'>
            <Text>
               You can review a question as many times as you consider necessary
               by clicking on its stem or number in the Question List. Once you
               have finished the review, click on the Finish Test button to exit
               the test and obtain the final report with the results.
            </Text>
         </div>
      </div>
   )
}
