import React, { Component } from "react"
import { Route, Redirect } from "react-router-dom"
import { isAuthorized } from "../controllers/AccountController"
import { isAdminAuthorized } from "../controllers/AdminController"
import { getUserType } from "../controllers/Authorization"
import { localRoutes } from "../routes"
import {
   adminPaths,
   preAdminPath,
   preStudentPath,
   studentRoutes,
} from "./Paths"
import { userTypes } from "./types"

export default class AuthorizedRoute extends Component {
   constructor(props) {
      super(props)
      this.state = {
         isAuthorized: false,
         isAuthorizing: true,
      }
   }

   componentDidMount() {
      if (!this.props.type || this.props.type === 1) {
         isAuthorized().then((result) => {
            this.setState({
               isAuthorized: result,
               isAuthorizing: false,
            })
         })
      } else {
         isAdminAuthorized().then((result) => {
            this.setState({
               isAuthorized: result,
               isAuthorizing: false,
            })
         })
      }
   }

   resolvePath(path) {
      if (!path) {
         if (getUserType() === userTypes.student) return studentRoutes.dashboard

         return adminPaths.dashboard
      }

      if (
         path.startsWith(preAdminPath) &&
         getUserType() === userTypes.student
      ) {
         return studentRoutes.dashboard
      }

      if (
         path.startsWith(preStudentPath) &&
         getUserType() === userTypes.admin
      ) {
         return adminPaths.dashboard
      }

      return path
   }

   render() {
      if (this.state.isAuthorizing) return <div></div>

      if (!this.state.isAuthorized) {
         if (getUserType() !== 0) {
            if (getUserType() === userTypes.student) {
               return <Redirect to={studentRoutes.login} />
            } else {
               return <Redirect to={adminPaths.login} />
            }
         }

         return (
            <Redirect
               to={
                  !this.props.type || this.props.type === 1
                     ? studentRoutes.login
                     : adminPaths.login
               }
            />
         )
      }

      if (this.resolvePath(this.props.path) !== this.props.path)
         return <Redirect to={this.resolvePath(this.props.path)} />

      return (
         <Route
            path={this.props.path}
            render={(props) => {
               return <this.props.component {...props} />
            }}
         />
      )
   }
}
