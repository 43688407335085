import React, { useEffect, useState } from "react"
import {
   Typography,
   Col,
   Row,
   Button,
   Input,
   Tag,
   Select,
   Space,
   Switch,
   InputNumber,
} from "antd"
import { Table } from "ant-table-extensions"
import {
   PlusOutlined,
   MenuOutlined,
   UploadOutlined,
   DownloadOutlined,
   SearchOutlined,
   SyncOutlined,
} from "@ant-design/icons"
import history from "../../../history"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"
import "../Dashboard.css"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import {
   addUser,
   exportUsers,
   getUsers,
   removeUser,
   updateUser,
} from "../../../controllers/AdminController"
import { getExamList } from "../../../controllers/ExamManagementController"
import ImportUsers from "../ExamManagement/ImportUsers"
import AllowedExams from "./AllowedExams"
import { downloadLocalFile } from "../../../helpers/Helpers"
import { adminPaths } from "../../../helpers/Paths"
import { getUserKey, getUserType, userTypes } from "../../../helpers/UserHelper"
import { getGroupsOverview } from "../../../controllers/GroupManagementController"
import SelectStudentGroups from "./Student/SelectStudentGroups"

const { Option } = Select
const { Title } = Typography
const { Search } = Input

export default function UserManagement() {
   const loadUsers = async function () {
      const el = await getExamList()
      setExamList(el.data)
      const response = await getUsers()
      setUsers(
         response.data.map((u) => {
            u.tUsername = u.username
            u.tFirstName = u.firstName
            u.tLastName = u.lastName
            u.tEmail = u.email
            u.tIsForeign = u.isForeign
            u.tCorrelationId = u.correlationId
            return u
         })
      )
   }
   const emptyModel = {
      FirstName: "",
      LastName: "",
      Username: "",
      Password: "",
      email: "",
      type: 1,
      removeStudentPolicy: 2,
      dateToRemove: new Date(),
      groupIds: [],
      groups: [],
      isForeign: false,
      correlationId: "",
   }
   const initModel = function () {
      setModel(emptyModel)
   }

   useEffect(() => {
      loadUsers()
   }, [])

   //#region Data

   const [examList, setExamList] = useState([])

   const [model, setModel] = useState(emptyModel)

   const [selectedFilter, setSelectedFilter] = useState(0)

   const filterOptions = [
      {
         label: "Filter",
         value: 0,
      },
      {
         label: "Student",
         value: 1,
      },
      {
         label: "Admin",
         value: 2,
      },
      {
         label: "User",
         value: 3,
      },
   ]

   const [users, setUsers] = useState([])

   const after = []

   for (var x = 0; x < 12; x++) {
      after.push({
         label: x + 1 + " Month",
         value: x + 1,
      })
   }

   const options = [
      {
         label: "Student",
         value: 1,
      },
      {
         label: "Admin",
         value: 2,
      },
      {
         label: "User",
         value: 3,
      },
   ]

   const studentRemoveOptions = [
      {
         label: "Auto Remove",
         value: 1,
      },
      {
         label: "Keep Student",
         value: 2,
      },
   ]

   const [showImportDialog, setShowImportDialog] = useState(false)

   //#endregion

   //#region Handlers

   const closeImportDialog = () => {
      setShowImportDialog(false)
   }

   const handleInput = function (name, value) {
      setModel({
         ...model,
         [name]: value,
      })
   }

   const addNewUser = async function () {
      const ms = {
         ...model,
         groupIds: model.groups.map((g) => g.id),
      }

      const response = await addUser(ms)

      if (!response.isSuccess) {
         if (response.errors) {
            let errors = ""
            response.errors.forEach((a) => {
               errors += a + "\n"
            })

            showErrorNotification(errors)
         } else if (response.message) {
            showErrorNotification(response.message)
         }
      } else {
         initModel()
         await loadUsers()
         showSuccessNotification(response.message)
      }
   }

   const setDeletingStatus = function (id, status) {
      setUsers(
         users.map((u) => {
            if (u.id === id) {
               return {
                  ...u,
                  deleting: status,
               }
            }
            return u
         })
      )
   }

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         render: (text, row) => (
            <p>
               {row.firstName} {row.lastName}
            </p>
         ),
      },
      {
         title: "Username",
         dataIndex: "username",
         key: "username",
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Type",
         dataIndex: "userType",
         key: "userType",
         render: (text, row) =>
            row.userType === getUserKey("student") ? (
               <Tag color='blue'>Student</Tag>
            ) : row.userType === getUserKey("admin") ? (
               <Tag color='red'>Admin</Tag>
            ) : (
               <Tag color='green'>User</Tag>
            ),
      },
      {
         title: "Action",
         dataIndex: "view",
         key: "view",
         render: (text, row) => (
            <>
               <Space>
                  <Button
                     type='link'
                     onClick={() => {
                        history.push(
                           adminPaths.userManagement +
                              "/user/" +
                              getUserType(row.userType).toLowerCase() +
                              "/" +
                              row.id
                        )
                     }}>
                     View
                  </Button>
                  {row.deleting ? (
                     <Tag icon={<SyncOutlined spin />} color='error'>
                        Deleting
                     </Tag>
                  ) : (
                     <Button
                        type='link'
                        className='text-red-500'
                        onClick={async () => {
                           setDeletingStatus(row.id, true)
                           const response = await removeUser(
                              row.id,
                              row.userType
                           )
                           setDeletingStatus(row.id, false)
                           if (response.isSuccess) {
                              setUsers(users.filter((u) => u.id !== row.id))
                              showSuccessNotification(response.message)
                           } else {
                              showErrorNotification(response.message)
                           }
                        }}>
                        delete
                     </Button>
                  )}
               </Space>
            </>
         ),
      },
   ]

   //#endregion

   return (
      <div className='overflow-auto'>
         <div className='m-5'>
            <div className='d-flex align-items-center justify-content-between mb-2'>
               <Title className='main-title' level={2}>
                  User Management
               </Title>
               <div className='d-flex'>
                  <div className='mr-5'>
                     <Button
                        className='d-flex align-items-center'
                        icon={<DownloadOutlined />}
                        type='dashed'
                        size='large'
                        onClick={async (c) => {
                           const users = await exportUsers()

                           if (users.isSuccess) {
                              downloadLocalFile("csv", users.data, "users")
                              showSuccessNotification(
                                 "CSV file exported successfully"
                              )
                           }
                        }}>
                        Export Users
                     </Button>
                  </div>

                  <div>
                     <Button
                        className='d-flex align-items-center'
                        icon={<UploadOutlined />}
                        type='dashed'
                        size='large'
                        onClick={async (c) => setShowImportDialog(true)}>
                        Import Users
                     </Button>
                  </div>
               </div>
            </div>
            <div className='border div-box rounded p-5'>
               <ImportUsers
                  onImport={async () => {
                     await loadUsers()
                  }}
                  onClose={closeImportDialog}
                  show={showImportDialog}
               />
               <Title className='mb-5' level={4}>
                  Add User
               </Title>
               <Row gutter={24}>
                  <Col span={8}>
                     <label for='firstName'>First Name:</label>
                     <Input
                        id='firstName'
                        size='large'
                        type='text'
                        placeholder='First Name'
                        value={model.FirstName}
                        onChange={(e) =>
                           handleInput("FirstName", e.target.value)
                        }
                     />
                  </Col>
                  <Col span={8}>
                     <label for='lastName'>Last Name:</label>
                     <Input
                        id='lastName'
                        size='large'
                        type='text'
                        placeholder='Last Name'
                        value={model.LastName}
                        onChange={(e) =>
                           handleInput("LastName", e.target.value)
                        }
                     />
                  </Col>
               </Row>
               <Row gutter={24} className='mt-5'>
                  <Col span={8}>
                     <label for='username'>Username:</label>
                     <form autoComplete='off'>
                        <Input
                           id='username'
                           size='large'
                           type='text'
                           maxLength={30}
                           placeholder='Username'
                           minLength={4}
                           value={model.Username}
                           onChange={(e) =>
                              handleInput("Username", e.target.value)
                           }
                        />
                     </form>
                  </Col>
                  <Col span={8}>
                     <label for='passWord'>Password:</label>
                     <form autoComplete='off'>
                        <Input.Password
                           id='password'
                           size='large'
                           placeholder='Password'
                           type='Password'
                           maxLength={30}
                           minLength={6}
                           value={model.Password}
                           onChange={(e) =>
                              handleInput("Password", e.target.value)
                           }
                        />
                     </form>
                  </Col>
               </Row>
               <Row gutter={24} className='mt-5'>
                  <Col span={8}>
                     <label for='emailInput'>Email:</label>
                     <Input
                        id='emailInput'
                        size='large'
                        type='email'
                        placeholder='Email'
                        value={model.email}
                        onChange={(e) => handleInput("email", e.target.value)}
                     />
                  </Col>
                  <Col span={8}>
                     <label for='userType'>Type:</label>
                     <Select
                        className='d-block'
                        id='userType'
                        size='large'
                        value={options[model.type - 1].value}
                        onChange={(e) => {
                           handleInput("type", e)
                        }}
                        placeholder='Select an option'>
                        {options.map((o) => {
                           return (
                              <Option key={o.value} value={o.value}>
                                 {o.label}
                              </Option>
                           )
                        })}
                     </Select>
                  </Col>
               </Row>
               <Row className='mt-2'>
                  <Col span={8}>
                     <div className='flex flex-col mr-4'>
                        <label for='emailInput'>Correlation Id:</label>
                        <Input
                           id='correlationId'
                           size='large'
                           className='w-100'
                           placeholder='Correlation Id'
                           value={model.correlationId}
                           onChange={(e) => {
                              handleInput("correlationId", e.target.value)
                           }}
                        />
                     </div>
                  </Col>
                  <Col>
                     <div className='d-flex flex-column ml-4'>
                        <label for='isForeign'>Is Foreign?</label>
                        <Switch
                           className='w-25'
                           size='large'
                           id='isForeign'
                           name='isForeign'
                           checked={model.isForeign}
                           onChange={(e) => {
                              handleInput("isForeign", e)
                           }}
                        />
                     </div>
                  </Col>
               </Row>
               <Row className='mt-5'>
                  {model.type === 1 ? (
                     <>
                        <Col span={4}>
                           <label for='userStatus'>Status:</label>
                           <Select
                              id='userStatus'
                              className='d-block'
                              size='large'
                              value={
                                 studentRemoveOptions[
                                    model.removeStudentPolicy - 1
                                 ].value
                              }
                              onChange={(e) => {
                                 handleInput("removeStudentPolicy", e)
                              }}
                              placeholder='Select an option'>
                              {studentRemoveOptions.map((o) => (
                                 <Option key={o.value} value={o.value}>
                                    {o.label}
                                 </Option>
                              ))}
                           </Select>
                        </Col>
                        {model.removeStudentPolicy === 1 ? (
                           <Col span={4}>
                              <label className='d-block' for='statusTime'>
                                 Time:
                              </label>
                              <Select
                                 id='statusTime'
                                 className='d-block'
                                 size='large'
                                 onChange={(e) => {
                                    var d = new Date()
                                    d.setMonth(d.getMonth() + e)

                                    setModel({
                                       ...model,
                                       dateToRemove: d,
                                    })
                                 }}
                                 defaultValue={after[0].value}
                                 placeholder='After Month'>
                                 {after.map((o) => (
                                    <Option key={o.value} value={o.value}>
                                       {o.label}
                                    </Option>
                                 ))}
                              </Select>
                           </Col>
                        ) : (
                           <></>
                        )}
                     </>
                  ) : (
                     <></>
                  )}
               </Row>
               {model.type === 1 ? (
                  <>
                     {model.groups.length > 0 ? (
                        <Row className='mt-4'>
                           <Col span={24}>
                              <div className='pt-2 pb-2'>
                                 {model.groups.map((g) => {
                                    return <Tag>{g.name}</Tag>
                                 })}
                              </div>
                           </Col>
                        </Row>
                     ) : (
                        <></>
                     )}
                     <Row className='mt-5'>
                        <Col span={8}>
                           <SelectStudentGroups
                              selectedGroups={model.groupIds}
                              onSelectionFinished={(gs) => {
                                 setModel({ ...model, groups: gs })
                              }}
                           />
                        </Col>
                     </Row>
                  </>
               ) : (
                  <></>
               )}
               <div className='d-flex justify-content-between mt-5 pt-5'>
                  <div></div>

                  <div>
                     <Button
                        className='d-flex align-items-center'
                        icon={<PlusOutlined />}
                        type='primary'
                        size='large'
                        onClick={async (c) => await addNewUser()}>
                        Add New User
                     </Button>
                  </div>
               </div>
            </div>

            <div className='p-5 mt-5 border div-box rounded mb-5'>
               <div className='d-flex'>
                  <label className='p-2 mb-5 flex-grow-1'>
                     <Title className='my-auto' level={4}>
                        Users
                     </Title>
                  </label>
                  <Dropdown
                     options={filterOptions}
                     value={filterOptions[selectedFilter]}
                     onChange={(e) => setSelectedFilter(e.value)}
                  />
               </div>
               <Table
                  searchableProps={{
                     // dataSource,
                     // setDataSource: setSearchDataSource,
                     inputProps: {
                        placeholder: "Search",
                        prefix: <SearchOutlined />,
                     },
                  }}
                  searchable={true}
                  columns={columns}
                  dataSource={users
                     .filter((e) => {
                        if (selectedFilter === 0) return e

                        if (e.userType === selectedFilter) return e
                     })
                     .sort((u) => u.username)}
               />
            </div>
         </div>
      </div>
   )
}
