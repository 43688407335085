import React, { Component } from "react"
import { Typography, Radio, Button, Input } from "antd"
import { ClockCircleOutlined } from "@ant-design/icons"
import { Jumbotron, Container } from "react-bootstrap"
import { getExams } from "../controllers/ExamController"
import { getReportPath, studentRoutes } from "../helpers/Paths"
import history from "../history"
import MainNavbar from "./MainNavbar"
import "./Student.css"
import { getAppVersion } from "../helpers/versionInfo"

const { Search } = Input
const { Title, Text, Paragraph } = Typography

export default class Dashboard extends Component {
   constructor(props) {
      super(props)

      const userData = JSON.parse(localStorage.getItem("userData"))

      this.state = {
         username: userData.userName,
         exams: [],
         showResult: false,
         selectedExamId: 0,
         examType: "a",
      }

      this.getExams = this.getExams.bind(this)
      this.showResult = this.showResult.bind(this)
   }

   async componentDidMount() {
      const exams = await getExams()
      this.setState({
         exams: exams.map((e) => {
            return { ...e, visible: true }
         }),
      })
   }

   showResult(e) {
      history.push(getReportPath(e))
      this.setState({
         showResult: true,
         selectedExamId: e,
      })
   }

   render() {
      return (
         <div>
            <Container className='pt-2 d-flex flex-column '>
               <MainNavbar username={this.state.username} />
               <Jumbotron className='mt-2 jumbo-tron'>
                  <Title className='welcome-title'>
                     Welcome to Exam Portal ({getAppVersion()})
                  </Title>
               </Jumbotron>

               <div className='flex'>
                  <div className='flex-1'>
                     <Radio.Group
                        onChange={(e) =>
                           this.setState({ examType: e.target.value })
                        }
                        className='mx-auto my-5'
                        size='large'
                        defaultValue='a'
                        buttonStyle='solid'>
                        <Radio.Button value='a'>All Exams</Radio.Button>
                        <Radio.Button value='b'>New Exams</Radio.Button>
                        <Radio.Button value='c'>Completed Exams</Radio.Button>
                     </Radio.Group>
                  </div>
                  <div className='flex align-items-center'>
                     <Search
                        className='mr-5'
                        style={{ width: "150px" }}
                        size='large'
                        onSearch={(value) => {
                           this.setState({
                              exams: this.state.exams.map((e) => {
                                 if (
                                    e.name
                                       .toLowerCase()
                                       .includes(value.toLowerCase())
                                 )
                                    return {
                                       ...e,
                                       visible: true,
                                    }

                                 return {
                                    ...e,
                                    visible: false,
                                 }
                              }),
                           })
                        }}
                        placeholder='Search Exam'
                     />
                  </div>
               </div>

               <div>{this.getExams()}</div>
            </Container>
         </div>
      )
   }

   getExams() {
      return this.state.exams
         .filter((e) => {
            if (e.taken === true && this.state.examType === "c") return e
            else if (e.taken === false && this.state.examType === "b") return e
            else if (this.state.examType === "a") return e
         })
         .map((e, k) => {
            if (!e.visible) return <></>
            var button = null
            var btnDemo = null

            if (e.allowDemo) {
               if (e.taken && e.isOver) {
                  btnDemo = (
                     <Button
                        size='middle'
                        className={e.allowDemo ? "d-inline" : "d-none"}
                        onClick={async () => await this.takeExam(e.id, 2)}>
                        Demo
                     </Button>
                  )
               } else if (!e.taken) {
                  btnDemo = (
                     <Button
                        size='middle'
                        className={e.allowDemo ? "d-inline" : "d-none"}
                        onClick={async () => await this.takeExam(e.id, 2)}>
                        Demo
                     </Button>
                  )
               }
            }

            if (e.taken && (e.isOver || !e.taken)) {
               button = (
                  <div className='d-flex justify-content-between m-5'>
                     <div>
                        <Button
                           size='middle'
                           type='primary'
                           className={"mr-2"}
                           onClick={async () => await this.takeExam(e.id, 1)}>
                           Take Again
                        </Button>
                        {btnDemo}
                     </div>

                     <div>
                        <Button
                           size='middle'
                           onClick={() => this.showResult(e.takenExamId)}>
                           Result
                        </Button>
                     </div>
                  </div>
               )
            } else if (e.taken) {
               button = (
                  <div>
                     <Button
                        size='middle'
                        className={"mr-2 "}
                        onClick={() => {
                           history.push(`exam/${e.takenExamId}`)
                        }}>
                        Continue now
                     </Button>
                     {btnDemo}
                  </div>
               )
            } else {
               button = (
                  <div>
                     <Button
                        size='middle'
                        className={"mr-2"}
                        onClick={async () => await this.takeExam(e.id, 1)}>
                        Take Exam
                     </Button>
                     {btnDemo}
                  </div>
               )
            }

            return (
               /*displaying each exam*/
               <Jumbotron key={"ec-" + k} className='p-5'>
                  <div className='d-flex m-5'>
                     <div>
                        <Title level={2} className='mb-0'>
                           {e.name}
                        </Title>
                        <Text className='mt-0 font-16'>
                           {e.noOfQuestions} Questions
                        </Text>
                        <Paragraph className='mt-5 w-50'>
                           {e.description}
                        </Paragraph>
                     </div>
                     <div className='ml-auto font-16'>
                        <div className='d-flex align-items-center'>
                           <ClockCircleOutlined />
                           <Text className='ml-2'>
                              {" "}
                              {e.durationInSec / 60} Minutes
                           </Text>
                        </div>
                     </div>
                  </div>
                  {button}
               </Jumbotron>
            )
         })
   }

   async takeExam(examId, mode) {
      history.push(studentRoutes.instructions, {
         examId: examId,
         mode: mode,
      })
   }
}
