import React, { useState } from "react"
import { Row, Col, Typography, Button, Alert, Input } from "antd"
import { Form, FormGroup, Label } from "reactstrap"
import { adminPaths, studentRoutes } from "../../helpers/Paths"
import { Login } from "../../controllers/AccountController"
import { setLocalUserData } from "../../controllers/Authorization"
import { Log } from "../../controllers/Logger"
import history from "../../history"
import WhiteLogo from "../../assets/Logo.png"
import ReCAPTCHA from "react-google-recaptcha"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"

const { Title, Text } = Typography

export default function AdminLogin() {
   const [username, setUsername] = useState("")
   const [password, setPassword] = useState("")
   const [recaptchaValue, setRecaptchaValue] = useState("")
   const [isError, setIsError] = useState(false)
   const [error, setError] = useState("")
   const [isLoading, setIsLoading] = useState(false)

   return (
      <div className='login-admin-bg h-100'>
         <a
            href='https://www.progettarelereti.com/store'
            target={"_blank"}
            className='login-logo'
            rel='noreferrer'>
            <img src={WhiteLogo} width='200' />
         </a>
         <Row className='d-flex align-items-center h-100'>
            <Col
               span={12}
               className='d-flex flex-column justify-content-center align-items-center h-100'>
               <Text className='login-bg-text'>
                  Admin
                  <br />
                  Login
               </Text>
               <Title className='login-title-text'>
                  Admin
                  <br />
                  Login
               </Title>
               <Button
                  className='mt-5 admin-login-btn'
                  type='dashed'
                  onClick={() => history.push(studentRoutes.login)}>
                  Switch to Student Login
               </Button>
            </Col>
            <Col span={12} className='d-flex justify-content-center'>
               <div className='login-form'>
                  <Title level={3} className='mb-5'>
                     Sign in to OnionCert.
                  </Title>
                  <Form
                     className='form'
                     method='post'
                     onSubmit={async (e) => {
                        e.preventDefault()

                        setIsLoading(true)

                        var data = await Login(
                           {
                              username: username,
                              password: password,
                              captchaResponse: recaptchaValue,
                           },
                           2
                        )
                        setIsLoading(false)

                        setIsError(!data.isSuccess)
                        setError(data.message)

                        if (data.isSuccess) {
                           setLocalUserData(data.data)
                           history.push(adminPaths.dashboard)
                        }

                        return false
                     }}>
                     <div className='login-inputs-div'>
                        <FormGroup className='mb-3 p-0'>
                           <Label>Username</Label>
                           <Input
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              type='text'
                              maxLength={30}
                              minLength={4}
                              name='email'
                              id='email'
                              placeholder='Email Address'
                           />
                        </FormGroup>
                        <FormGroup>
                           <Label for='passowrd'>Password</Label>
                           <Input.Password
                              value={password}
                              maxLength={30}
                              minLength={6}
                              onChange={(e) => setPassword(e.target.value)}
                              type='password'
                              iconRender={(visible) =>
                                 visible ? (
                                    <EyeTwoTone />
                                 ) : (
                                    <EyeInvisibleOutlined />
                                 )
                              }
                              name='password'
                              placeholder='Password'
                           />
                        </FormGroup>
                        <ReCAPTCHA
                           sitekey='6LcHz3IcAAAAACUCtdE8YhvV-ICDc-4_Wpl1egIN'
                           onChange={(v) => setRecaptchaValue(v)}
                        />
                     </div>
                     <div className='login-btn-div'>
                        <Button
                           className='btn-primary w-100'
                           htmlType='submit'
                           size='large'
                           loading={isLoading}
                           type='primary'>
                           Login
                        </Button>
                        <Alert
                           className={isError ? "d-flex mt-5" : "d-none"}
                           message={error}
                           type='error'
                           showIcon
                        />
                     </div>
                  </Form>
               </div>
            </Col>
         </Row>
      </div>
   )
}
