import React, { useState } from "react"
import { Typography, Image, Collapse, Row, Col } from "antd"
import {
   Button,
   FormGroup,
   Input,
   InputGroup,
   InputGroupText,
   Label,
} from "reactstrap"
import { shuffleArray } from "../helpers/Helpers"
import "./Student.css"

const { Title } = Typography
const { Panel } = Collapse

export default function QNA({ onSelectedAnswerChanged, ...props }) {
   const getAnswer = function () {
      return props.question.answers.map((a, i) => {
         return (
            <InputGroup
               className='mb-3 p-3 w-50 bg-white rounded'
               key={"a-" + i}>
               <FormGroup check className='d-flex align-items-center'>
                  <Input
                     id={i + "i"}
                     type={
                        props.question.answerType === 1 ? "radio" : "checkbox"
                     }
                     name='answers'
                     value={a.id}
                     checked={props.question.answer.includes(a.id)}
                     onChange={(e) => (props.question.answer = a.id)}
                  />
                  <Label
                     className='ml-3'
                     style={{ fontSize: "16px" }}
                     check
                     for={i + "i"}>
                     {a.text}
                  </Label>
               </FormGroup>
            </InputGroup>
         )
      })
   }

   const [showAnswers, setShowAnswers] = useState(false)

   const getCorrectAnswer = function () {
      if (props.question.showAnswer) {
         return (
            <Collapse
               className='w-50 border border-success'
               expandIconPosition='right'>
               <Panel header='View Answer'>
                  <ol>
                     {props.question.answers
                        .filter((e) => e.correct)
                        .map((a) => (
                           <li>{a.text}</li>
                        ))}
                  </ol>
               </Panel>
            </Collapse>
         )
      }
   }

   return (
      <div className='m-4 position-relative'>
         <Title level={4} className='mb-5'>
            {props.question.text}
         </Title>

         <Row>
            <Col span={16}>
               <div
                  onChange={async (e) => {
                     await onSelectedAnswerChanged(e.target.value)
                  }}>
                  {getAnswer()}
               </div>
            </Col>
            <Col span={8}>
               <div>
                  {props.question.imageUrl && props.question.imageUrl !== "" ? (
                     <Image
                        alt='Question Image'
                        src={props.question.imageUrl}
                     />
                  ) : null}
               </div>
            </Col>
         </Row>
         <div style={{ marginTop: "50px" }}>{getCorrectAnswer()}</div>
         <div
            className={
               props.question.imageUrl !== "" &&
               props.question.answers.length < 3
                  ? "clear-div-2"
                  : ""
            }></div>
      </div>
   )
}
