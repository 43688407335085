import React, { useEffect, useState } from "react"
import { Typography } from "antd"

const { Title, Text } = Typography

export default function Page1({ examId, examInfo, ...props }) {
   return (
      <div className='d-flex my-5 py-5 flex-column'>
         <Title level={2} className='mb-5'>
            Exam Instructions
         </Title>
         <div className='bg-light p-5 rounded'>
            <Title level={4}>Test Information</Title>
            <Text>{examInfo.testInformation}</Text>
         </div>

         <div className='bg-light mt-3 p-5 rounded'>
            <Title level={4}>Test Instructions</Title>
            <Text>{examInfo.instructions}</Text>
         </div>
      </div>
   )
}
