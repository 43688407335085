import React from "react"
import { Link } from "react-router-dom"
import { studentRoutes } from "../../helpers/Paths"

export default function MagicLinkExamResult({ history, location, ...props }) {
   const { results } = location

   if (!results) {
      history.push(studentRoutes.login)
      return <></>
   }

   return (
      <div className='flex flex-1 flex-col h-full w-full text-center justify-center items-center'>
         <p className='p-0 text-3xl'>
            {"You have " +
               (results.isPassed ? "Passed" : "Failed") +
               " the Exam."}
         </p>
         <p className='p-0 text-3xl'>
            Click <Link to='https://app.workersbadge.com'>here</Link> to get
            back to Workers Badge
         </p>
      </div>
   )
}
