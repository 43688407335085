import { adminApiUrls } from "../routes"
import { doAuthorizedGetCall, doAuthorizedPostCall } from "./AdminController"

export async function getAllExam() {
   const response = await doAuthorizedGetCall(adminApiUrls.exam.all)

   return response.data
}

export async function getExamById(id) {
   const response = await doAuthorizedGetCall(adminApiUrls.exam.get + id)
   return response.data
}

export async function addExam(exam) {
   console.log(exam)
   if (exam.durationInSec === 0 || exam.durationInSec === "") {
      exam.durationInSec = 10 * 60
   }
   if (exam.maxQuestions === 0) {
      exam.maxQuestions = 10
   }
   if (exam.passingScore === 0) {
      exam.passingScore = 6
   }
   if (!exam.correlationId) {
      exam.correlationId = null
   }
   if (!exam.examType || exam.examType <= 0) {
      exam.examType = 2
   }
   if (!exam.teachers || exam.teachers === "") {
      exam.teachers = "Teacher 1 | Teacher 2"
   }
   if (!exam.instructions || exam.instructions === "") {
      exam.instructions = "Test"
   }
   if (!exam.testInformation || exam.testInformation === "") {
      exam.testInformation = "Test"
   }

   const response = await doAuthorizedPostCall(adminApiUrls.exam.add, exam)
   return response.data
}

export async function updateExam(exam) {
   const response = await doAuthorizedPostCall(adminApiUrls.exam.update, exam)
   return response.data
}

export async function deleteExam(id) {
   const response = await doAuthorizedGetCall(adminApiUrls.exam.delete + id)
   return response.data
}

//sections
export async function getAllSections(id) {
   const response = await doAuthorizedGetCall(
      adminApiUrls.exam.section.all + id
   )

   return response.data
}

export async function addSection(section) {
   const response = await doAuthorizedPostCall(
      adminApiUrls.exam.section.add,
      section
   )
   return response.data
}

export async function updateSection(section) {
   const response = await doAuthorizedPostCall(
      adminApiUrls.exam.section.update,
      section
   )
   return response.data
}

export async function deleteSection(id, examId) {
   const response = await doAuthorizedGetCall(
      adminApiUrls.exam.section.delete + id + "?examId=" + examId
   )
   return response.data
}

//question

export async function getQuestionById(id) {
   const response = await doAuthorizedGetCall(
      adminApiUrls.exam.questions.get + id
   )
   return response.data
}

export async function addQuestion(question) {
   const response = await doAuthorizedPostCall(
      adminApiUrls.exam.questions.add,
      question
   )
   return response.data
}

export async function updateQuestion(question) {
   const response = await doAuthorizedPostCall(
      adminApiUrls.exam.questions.update,
      question
   )
   return response.data
}

export async function deleteQuestion(id, examId) {
   const response = await doAuthorizedGetCall(
      adminApiUrls.exam.questions.delete + id + "?examId=" + examId
   )
   return response.data
}

//answers
export async function getAllAnswers() {
   const response = await doAuthorizedGetCall(adminApiUrls.exam.answers.all)

   return response.data
}

export async function addAnswer(answer) {
   const response = await doAuthorizedPostCall(
      adminApiUrls.exam.answers.add,
      answer
   )
   return response.data
}

export async function updateAnswer(answer) {
   const response = await doAuthorizedPostCall(
      adminApiUrls.exam.answers.update,
      answer
   )
   return response.data
}

export async function deleteAnswer(id, qid) {
   const response = await doAuthorizedGetCall(
      adminApiUrls.exam.answers.delete + id + "?questionId=" + qid
   )
   return response.data
}

export async function getAllowedGroups(id) {
   return (await doAuthorizedGetCall(adminApiUrls.exam.getAllowedGroups + id))
      .data
}

export async function updateAllowedGroups(id, groups) {
   return (
      await doAuthorizedPostCall(
         adminApiUrls.exam.updateAllowedGroups + id,
         groups
      )
   ).data
}

export async function getStudentResult(sid, eid) {
   return (
      await doAuthorizedGetCall(
         adminApiUrls.exam.result + sid + "?examId=" + eid
      )
   ).data
}

export async function getExamList() {
   return (await doAuthorizedGetCall(adminApiUrls.exam.list)).data
}

export async function exportQuestions(examId) {
   return (
      await doAuthorizedGetCall(adminApiUrls.exam.exportQuestions + examId)
   ).data
}

export async function importQuestions(data) {
   return (await doAuthorizedPostCall(adminApiUrls.exam.importQuestions, data))
      .data
}
