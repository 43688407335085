import { Typography } from "antd"
import React, { useState } from "react"
import { useEffect } from "react"
import { Container, Jumbotron } from "react-bootstrap"
import { getUsername } from "../../controllers/Authorization"
import { getStudentTakenExams } from "../../controllers/ExamController"
import { showErrorNotification } from "../../helpers/NotificationSystem"
import StudentTakenExam from "../admin/UserManagement/StudentTakenExam"
import MainNavbar from "../MainNavbar"

const { Title } = Typography

export default function StudentResults() {
   const [takenExams, setTakenExams] = useState([])

   useEffect(() => {
      const loadTakenExams = async () => {
         const te = await getStudentTakenExams()

         if (te.isSuccess) {
            setTakenExams(te.data)
         } else showErrorNotification(te.message)
      }

      loadTakenExams()
   }, [])
   return (
      <Container className='pt-2 d-flex flex-column '>
         <MainNavbar username={getUsername()} />
         <Jumbotron className='mt-2 jumbo-tron'>
            <Title className='welcome-title'>Student Results</Title>
         </Jumbotron>
         <div className='mt-4'>
            <StudentTakenExam from='student' exams={takenExams} />
         </div>
      </Container>
   )
}
