export const userTypes = [
   {
      type: "Admin",
      key: 2,
   },
   {
      type: "Student",
      key: 1,
   },
   {
      type: "User",
      key: 3,
   },
]

export function getUserKey(type) {
   return userTypes.find(
      (user) => user.type.toLowerCase() === type.toLowerCase()
   ).key
}

export function getUserType(key) {
   return userTypes.find((user) => user.key === key).type
}
