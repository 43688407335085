import {
   Button,
   Col,
   Collapse,
   Divider,
   Input,
   Popconfirm,
   Row,
   Space,
   Switch,
   Upload,
} from "antd"
import { Table } from "ant-table-extensions"
import Checkbox from "antd/lib/checkbox/Checkbox"
import TextArea from "antd/lib/input/TextArea"
import React, { useEffect, useState } from "react"
import {
   addAnswer,
   deleteAnswer,
   updateAnswer,
} from "../../../controllers/ExamManagementController"
import { FaCheck, FaTimes } from "react-icons/fa"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"
import Modal from "antd/lib/modal/Modal"

const { Panel } = Collapse

export default function Answers({
   examId,
   questionId,
   sectionId,
   questionsAnswers,
   onUpdate,
   ...props
}) {
   const [newAnswer, setNewAnswer] = useState({
      id: 0,
      text: "",
      isCorrect: false,
      label: "",
      questionId: questionId,
      wrongDescription: "",
      rightDescription: "",
   })

   const handleInput = (name, value) => {
      setNewAnswer({
         ...newAnswer,
         [name]: value,
      })
   }

   const handleUpdateInput = (id, name, value) => {
      setAnswers(
         answers.map((a) => {
            if (a.id === id) {
               const x = {
                  ...a,
                  updated: {
                     ...a.updated,
                     [name]: value,
                  },
               }
               return x
            }

            return a
         })
      )
   }

   const getUpdateModel = (a) => {
      return {
         id: a.id,
         text: a.text,
         isCorrect: a.isCorrect,
         label: a.label,
         questionId: questionId,
         wrongDescription: a.wrongDescription,
         rightDescription: a.rightDescription,
      }
   }

   const [answers, setAnswers] = useState(
      questionsAnswers.map((a) => {
         a.updated = getUpdateModel(a)

         a.showUpdateDialog = false

         return a
      })
   )

   const setModalVisibility = (id, visible) => {
      setAnswers(
         answers.map((a) => {
            if (a.id === id) {
               a = {
                  ...a,
                  showUpdateDialog: visible,
               }
            }

            return a
         })
      )
   }

   const getAnswers = () => {
      const columns = [
         {
            title: "Label",
            dataIndex: "label",
            key: "label",
         },
         {
            title: "Text",
            dataIndex: "text",
            key: "text",
         },
         {
            title: "Correct",
            dataIndex: "isCorrect",
            key: "isCorrect",
            render: (c) => (c ? <FaCheck /> : <FaTimes />),
         },
         {
            title: "Actions",
            key: "actions",
            render: (text, record) => {
               return (
                  <Space size='middle'>
                     <a
                        className='text-primary'
                        onClick={() => {
                           setModalVisibility(record.id, true)
                        }}>
                        Update
                     </a>

                     <Modal
                        okText='Update'
                        title='Update Answer'
                        onCancel={() => {
                           setModalVisibility(record.id, false)
                        }}
                        onOk={async () => {
                           const response = await updateAnswer(record.updated)

                           if (response.isSuccess) {
                              showSuccessNotification("Question Updated!")
                              const x = answers.map((a) => {
                                 if (a.id == record.id) {
                                    const na = response.data

                                    na.updated = getUpdateModel(na)
                                    return na
                                 }

                                 return a
                              })
                              setAnswers(x)
                              onUpdate(x)
                           } else
                              showErrorNotification(
                                 "Unable to updated question!"
                              )
                        }}
                        visible={record.showUpdateDialog}>
                        <div>
                           <Row gutter={16}>
                              <Col span={6}>Label</Col>
                              <Col span={12}>Text</Col>
                              <Col span={6}>Is Correct?</Col>
                           </Row>
                           <Row gutter={16}>
                              <Col span={6}>
                                 <Input
                                    type='text'
                                    placeholder='Enter Label'
                                    name='label'
                                    value={record.updated.label}
                                    onChange={(e) =>
                                       handleUpdateInput(
                                          record.id,
                                          e.target.name,
                                          e.target.value
                                       )
                                    }
                                 />
                              </Col>
                              <Col span={12}>
                                 <Input
                                    type='text'
                                    placeholder='Enter Text'
                                    name='text'
                                    value={record.updated.text}
                                    onChange={(e) =>
                                       handleUpdateInput(
                                          record.id,
                                          e.target.name,
                                          e.target.value
                                       )
                                    }
                                 />
                              </Col>
                              <Col span={6}>
                                 <Checkbox
                                    name='isCorrect'
                                    checked={record.updated.isCorrect}
                                    onChange={(e) => {
                                       handleUpdateInput(
                                          record.id,
                                          e.target.name,
                                          e.target.checked
                                       )
                                    }}
                                 />
                              </Col>
                           </Row>
                           <Row gutter={16} className='mt-3'>
                              <Col span={12}>
                                 <TextArea
                                    placeholder='Wrong Description'
                                    rows={4}
                                    name='wrongDescription'
                                    value={record.updated.wrongDescription}
                                    onChange={(e) =>
                                       handleUpdateInput(
                                          record.id,
                                          e.target.name,
                                          e.target.value
                                       )
                                    }
                                 />
                              </Col>
                              <Col span={12}>
                                 <TextArea
                                    placeholder='Right Description'
                                    rows={4}
                                    name='rightDescription'
                                    value={record.updated.rightDescription}
                                    onChange={(e) => {
                                       handleUpdateInput(
                                          record.id,
                                          e.target.name,
                                          e.target.value
                                       )
                                    }}
                                 />
                              </Col>
                           </Row>
                        </div>
                     </Modal>
                     <Popconfirm
                        title='Are you sure to delete this answer?'
                        onConfirm={async () => {
                           const response = await deleteAnswer(
                              record.id,
                              questionId
                           )

                           if (response.isSuccess) {
                              const updatedAnswer = answers.filter(
                                 (a) => a.id !== record.id
                              )
                              setAnswers(updatedAnswer)
                              onUpdate(updatedAnswer)
                           } else {
                              showErrorNotification("Unable to delete answer")
                           }
                        }}
                        okText='Yes'
                        cancelText='No'>
                        <a className='text-danger'>Delete</a>
                     </Popconfirm>
                  </Space>
               )
            },
         },
      ]

      return (
         <Table
            searchable
            className='mt-2'
            columns={columns}
            dataSource={answers}
            pagination={{ pageSize: 5 }}
         />
      )
   }

   return (
      <div>
         <Row gutter={24}>
            <Col span={4}>
               <label for='enterLabel'>Enter Label</label>
               <Input
                  id='enterLabel'
                  size='large'
                  type='text'
                  name='label'
                  value={newAnswer.label}
                  onChange={(e) => handleInput(e.target.name, e.target.value)}
               />
            </Col>
            <Col span={12}>
               <label for='enterText'>Enter Text</label>
               <Input
                  id='enterText'
                  size='large'
                  type='text'
                  name='text'
                  value={newAnswer.text}
                  onChange={(e) => handleInput(e.target.name, e.target.value)}
               />
            </Col>
            <Col className='d-flex flex-column' span={4}>
               <label for='correct'>Is Correct?</label>
               <Switch
                  className='w-25 mt-2'
                  id='correct'
                  size='large'
                  name='isCorrect'
                  checked={newAnswer.isCorrect}
                  onChange={(e) => {
                     handleInput("isCorrect", e)
                  }}>
                  Is Correct ?
               </Switch>
            </Col>
         </Row>
         <Row gutter={24} className='mt-5'>
            <Col span={12}>
               <label for='wrongDiscription'>Wrong Description</label>
               <TextArea
                  id='wrongDiscription'
                  rows={4}
                  name='wrongDescription'
                  value={newAnswer.wrongDescription}
                  onChange={(e) => handleInput(e.target.name, e.target.value)}
               />
            </Col>
            <Col span={12}>
               <label for='rightDiscription'>Right Description</label>
               <TextArea
                  rows={4}
                  name='rightDescription'
                  value={newAnswer.rightDescription}
                  onChange={(e) => handleInput(e.target.name, e.target.value)}
               />
            </Col>
         </Row>
         <div className='d-flex justify-content-end mt-5'>
            <Button
               size='large'
               type='primary'
               onClick={async () => {
                  const response = await addAnswer(newAnswer)
                  if (response.isSuccess) {
                     const updatedAnswers = [
                        {
                           ...response.data,
                           updated: getUpdateModel(response.data),
                        },
                        ...answers,
                     ]
                     setAnswers(updatedAnswers)
                     onUpdate(updatedAnswers)
                  } else {
                     showErrorNotification(response.combinedErrors)
                  }
               }}>
               Add Answer
            </Button>
         </div>
         <Divider orientation='left'>Answers</Divider>
         {getAnswers()}
      </div>
   )
}
