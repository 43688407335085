import React from "react"
import { Typography, InputNumber, Switch } from "antd"
import { Accordion, Button, Card } from "react-bootstrap"
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap"

const { Title, Text } = Typography

export default function CustomizeExam({
   sections,
   setSections,
   updateTime,
   props,
}) {
   function onChangeTime(value) {
      updateTime(parseInt(value))
   }

   const handleSectionSelection = function (sid) {
      const newSections = [
         ...sections.map((s) => {
            if (s.id !== sid) return s
            s.allow = !s.allow
            return s
         }),
      ]

      setSections(newSections)
   }

   const getSections = function () {
      if (!sections) return null

      return sections.map((s, i) => {
         return (
            <div
               key={"s-s-" + i}
               className='bg-light rounded p-5 d-flex align-items-center w-75 my-2'>
               <Switch
                  defaultChecked
                  onChange={() => handleSectionSelection(s.id)}
                  checked={s.allow}
               />
               <div className='vertical-line mx-5'></div>
               <Title level={5}>{s.name}</Title>
            </div>
         )
      })
   }

   return (
      <div className='d-flex flex-column my-5 py-5'>
         <Title level={2} className='mb-5'>
            Customize Exam
         </Title>
         <Title level={4} className='mb-1'>
            Set time for the exam
         </Title>
         <Text className='mb-1'>Enter minutes between 1 - 60</Text>
         <InputNumber
            className='mb-5'
            min={"1"}
            max={"60"}
            size='large'
            defaultValue={10}
            onChange={onChangeTime}
         />
         <Title level={4} className='mt-2 mb-1'>
            Select Sections for the exam
         </Title>
         <Text className='mb-2'>Check the Section to Select</Text>
         <div className='d-flex flex-column h-100'>{getSections()}</div>
      </div>
   )
}
