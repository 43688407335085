import { LoadingOutlined } from "@ant-design/icons"
import { Button, message, Space, Spin } from "antd"
import React, { useEffect } from "react"
import { useState } from "react"
import {
   setCustomAuthorizationToken,
   useCustomAuthorizationToken,
} from "../../controllers/Authorization"
import { getSectionedExam } from "../../controllers/ExamController"
import { getMagicLinkInfo } from "../../controllers/foriegnstudent/ForiegnStudentMagicLinkController"
import { studentRoutes } from "../../helpers/Paths"

export default function MagicLinkEndPoint(props) {
   const [loading, setLoading] = useState(true)
   const [magicLinkInfo, setMagicLinkInfo] = useState({})

   useEffect(() => {
      const loadData = async () => {
         if (props?.match?.params?.id) {
            const result = await getMagicLinkInfo(props?.match?.params?.id)
            if (result.isSuccess) {
               setCustomAuthorizationToken(result.data.token)
               setLoading(false)
               setMagicLinkInfo(result.data)
            } else {
               props.history.push("/")
            }
         } else {
            props.history.push("/")
         }
      }

      loadData()
   }, [])
   const takeExam = (mode) => {
      props.history.push(studentRoutes.instructions, {
         examId: magicLinkInfo.examId,
         mode: mode,
         back: true,
      })
   }
   return (
      <div className='flex flex-1 h-full w-full items-center justify-center'>
         <Spin indicator={<LoadingOutlined size={50} />} spinning={loading}>
            <div className='flex flex-1 items-center justify-center'>
               <Space direction='horizontal'>
                  {magicLinkInfo.isOver && (
                     <p className='text-3xl p-0'>Exam Over</p>
                  )}
                  {magicLinkInfo && magicLinkInfo.isOver ? (
                     <></>
                  ) : magicLinkInfo.inProgress ? (
                     <>
                        <Button
                           onClick={() => {
                              props.history.push(
                                 `/student/exam/${magicLinkInfo.takenExamId}`
                              )
                           }}
                           size='large'
                           type='primary'>
                           Continue Exam
                        </Button>
                     </>
                  ) : (
                     <>
                        {magicLinkInfo.allowDemo && (
                           <Button
                              onClick={() => {
                                 takeExam(2)
                              }}
                              size='large'
                              type='primary'>
                              Take Demo
                           </Button>
                        )}
                        {magicLinkInfo.allowFinal && (
                           <Button
                              onClick={() => {
                                 takeExam(1)
                              }}
                              size='large'
                              type='dashed'>
                              Take Exam
                           </Button>
                        )}
                     </>
                  )}
               </Space>
            </div>
         </Spin>
      </div>
   )
}
