import React, { useEffect, useState } from "react"
import { Button, Input, Tag, Table, Select } from "antd"
import { updateAllowedExam } from "../../../../controllers/AdminController"
import { showErrorNotification } from "../../../../helpers/NotificationSystem"

const { Option } = Select

export default function EditorAllowedExams({
   allowedExams,
   examList,
   userId,
   ...props
}) {
   const [exams, setExams] = useState([])

   useEffect(() => {
      setExams(allowedExams)
   }, [allowedExams])

   const col = [
      {
         title: "Exam",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Action",
         render: (text, record) => (
            <Tag
               id={"t---" + record.id}
               color='red'
               onClick={async () => {
                  var response = await updateAllowedExam(
                     exams.filter((e) => e.id !== record.id).map((e) => e.id),
                     userId
                  )

                  if (response.isSuccess) {
                     setExams(exams.filter((e) => e.id !== record.id))
                  }
               }}
               style={{ cursor: "pointer" }}>
               Delete
            </Tag>
         ),
      },
   ]

   const [selectedExam, setSelectedExam] = useState(null)

   return (
      <div className='mt-2 w-100'>
         <div className='d-flex w-100'>
            <Select
               defaultValue={examList.length > 0 ? examList[0].id : null}
               value={selectedExam}
               placeholder='Select Exam To Allow'
               onChange={(value) => {
                  setSelectedExam(value)
               }}
               className='flex-grow-1 mr-3'>
               {examList.map((e) => (
                  <Option value={e.id}>{e.name}</Option>
               ))}
            </Select>
            <Button
               onClick={async () => {
                  if (selectedExam === null) {
                     showErrorNotification("Select Exam")
                     return
                  }
                  var ue = [...exams.map((e) => e.id), selectedExam]
                  const response = await updateAllowedExam(ue, userId)

                  if (response.isSuccess) {
                     if (exams.filter((a) => a.id === selectedExam).length > 0)
                        return
                     setExams([
                        ...exams,
                        examList.filter((e) => e.id === selectedExam)[0],
                     ])
                  }
               }}>
               Add
            </Button>
         </div>
         <div className='mt-2'>
            <Table dataSource={exams} columns={col} />
         </div>
      </div>
   )
}
