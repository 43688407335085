import React, { useState } from "react"
import { useEffect } from "react"
import {
   deleteStudentGroup,
   getStudentGroupDetail,
   updateStudentGroup,
} from "../../../controllers/GroupManagementController"
import {
   Button,
   Col,
   Input,
   InputNumber,
   Row,
   Switch,
   Table,
   Typography,
} from "antd"
import { UploadOutlined } from "@ant-design/icons"
import history from "../../../history"
import { adminPaths } from "../../../helpers/Paths"
import {
   showErrorNotification,
   showSuccessNotification,
} from "../../../helpers/NotificationSystem"

const { Title } = Typography

export default function GroupView(props) {
   const { id } = props.match.params
   const [groupModel, setGroupModel] = useState({
      id: 0,
      name: "",
      isForeign: false,
      groupCorrelationId: "",
      useDestination: 0,
   })
   const [users, setUsers] = useState([])

   useEffect(() => {
      async function load() {
         const response = await getStudentGroupDetail(id)
         if (response.isSuccess) {
            setGroupModel({
               name: response.data.name,
               id: response.data.id,
               useDestination: response.data.useDestination,
               isForeign: response.data.isForeign,
               groupCorrelationId: response.data.groupCorrelationId,
            })
            setUsers(response.data.students)
         } else {
            history.push(adminPaths.groupManagement)
         }
      }

      load()
   }, [])

   return (
      <div className='overflow-auto'>
         <div className='m-5'>
            <div className='d-flex align-items-center justify-content-between mb-2'>
               <Title className='main-title' level={2}>
                  User Management
               </Title>
            </div>
            <div className='border div-box rounded p-5'>
               <Title className='mb-5' level={4}>
                  Update Group
               </Title>

               <Row>
                  <Col span={7}>
                     <label for='name'>Group Name:</label>
                     <Input
                        id='firstName'
                        size='large'
                        type='text'
                        value={groupModel.name}
                        onChange={(e) =>
                           setGroupModel({
                              ...groupModel,
                              name: e.target.value,
                           })
                        }
                     />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                     <div className='flex flex-col'>
                        <label for='groupCorrelationId'>
                           Group Correlation Id:
                        </label>

                        <Input
                           id='groupCorrelationId'
                           size='large'
                           className='w-100'
                           type='text'
                           value={groupModel.groupCorrelationId}
                           onChange={(e) =>
                              setGroupModel({
                                 ...groupModel,
                                 groupCorrelationId: e.target.value,
                              })
                           }
                        />
                     </div>
                  </Col>
                  <Col span={1} />
                  <Col span={8}>
                     <div className='flex flex-col'>
                        <label for='useDestination'>Use Destination:</label>

                        <InputNumber
                           className='w-100'
                           id='useDestination'
                           size='large'
                           type='text'
                           value={groupModel.useDestination}
                           onChange={(e) => {
                              setGroupModel({
                                 ...groupModel,
                                 useDestination: parseInt(e),
                              })
                           }}
                        />
                     </div>
                  </Col>
               </Row>
               <div className='mt-4 flex flex-1 justify-between ml-1'>
                  <div className='items-center d-flex flex-row'>
                     <label for='allowDemo' className='m-0'>
                        Is Foreign? &nbsp;
                     </label>
                     <Switch
                        className='w-25'
                        size='large'
                        id='allowDemo'
                        name='allowDemo'
                        checked={groupModel.isForeign}
                        onChange={(e) => {
                           setGroupModel({
                              ...groupModel,
                              isForeign: e,
                           })
                        }}
                     />
                  </div>
                  <div className='flex flex-row'>
                     <Button
                        onClick={async () => {
                           const response = await updateStudentGroup(groupModel)

                           if (response.isSuccess) {
                              showSuccessNotification(response.message)
                           } else showErrorNotification(response.combinedErrors)
                        }}
                        size='large'
                        type='primary'
                        icon={<UploadOutlined />}>
                        Update
                     </Button>

                     <div className='d-flex justify-content-end w-100 flex-grow-1'>
                        <Button
                           type='dashed'
                           danger
                           size='large'
                           onClick={async () => {
                              const response = await deleteStudentGroup(
                                 groupModel.id
                              )
                              if (response.isSuccess) {
                                 showSuccessNotification(response.message)
                                 history.push(adminPaths.groupManagement)
                              } else showErrorNotification(response.message)
                           }}
                           className='ml-3'>
                           {" "}
                           Delete{" "}
                        </Button>
                     </div>
                  </div>
               </div>
            </div>

            <div className='border div-box rounded p-5 mt-5'>
               <Title className='mb-5' level={4}>
                  Users
               </Title>

               <Table
                  dataSource={users}
                  columns={[
                     {
                        title: "Id",
                        key: "id",
                        dataIndex: "id",
                     },
                     {
                        title: "Name",
                        dataIndex: "name",
                        key: "name",
                     },
                     {
                        title: "Action",
                        render: (text, record) => (
                           <>
                              <Button
                                 type='link'
                                 onClick={() => {
                                    history.push(
                                       adminPaths.userManagement +
                                          "/user/" +
                                          "student" +
                                          "/" +
                                          record.id
                                    )
                                 }}>
                                 View
                              </Button>
                           </>
                        ),
                     },
                  ]}
               />
            </div>
         </div>
      </div>
   )
}
