import React, { Component } from "react"
import { Button, Typography } from "antd"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"
import { Container } from "react-bootstrap"
import {
   continueExam,
   submitQuestion,
   finishExam,
} from "../controllers/ExamController"
import QNA from "./QNA"
import history from "../history"
import { shuffleArray } from "../helpers/Helpers"
import { getReportPath } from "../helpers/Paths"
import ExamNavBar from "./ExamNavBar"
import "./Student.css"

const { Title } = Typography

export default class Exam extends Component {
   constructor(props) {
      super(props)

      this.state = {
         questions: [],
         selectedQuestion: null,
         selectedQuestionIndex: 0,
         totalSecondRemaining: -1,
         timer: {
            h: 0,
            m: 0,
            s: 0,
         },
         mode: 1,
         name: "",
      }

      this.examId = -1
      this.timer = 0

      this.btnBackClicked = this.btnBackClicked.bind(this)
      this.btnNextClicked = this.btnNextClicked.bind(this)
      this.getQuestion = this.getQuestion.bind(this)
      this.onSelectedAnswerChanged = this.onSelectedAnswerChanged.bind(this)
      this.startTimer = this.startTimer.bind(this)
      this.countDown = this.countDown.bind(this)
      this.getRemainingTime = this.getRemainingTime.bind(this)
      this.finishExam = this.finishExam.bind(this)
   }

   componentDidUpdate() {
      if (this.state.mode === 2) {
         localStorage.setItem(
            "exam",
            JSON.stringify({
               questions: this.state.questions,
               mode: 2,
               name: this.state.name,
               startTimmer: this.state.startTimer,
               totalSecondRemaining: this.state.totalSecondRemaining,
               selectedQuestionIndex: this.state.selectedQuestionIndex,
               selectedQuestion: this.state.selectedQuestion,
            })
         )
      }
      if (this.state.totalSecondRemaining === 0) {
         this.finishExam()
         alert("Exam Over!")
      }
   }

   shuffleQNA(question) {
      return shuffleArray(
         question.map((q) => {
            return {
               ...q,
               answers: shuffleArray(q.answers),
            }
         })
      )
   }

   async componentDidMount() {
      this.examId = this.props.match.params.id

      if (this.examId === "demo") {
         if (this.props.location.exam) {
            this.setState(
               {
                  questions: this.shuffleQNA(
                     this.props.location.exam.questions
                  ),
                  name: this.props.location.exam.name,
                  mode: 2,
                  startTimmer: true,
                  totalSecondRemaining: this.props.location.exam.totalSeconds,
               },
               () => {
                  this.startTimer()
               }
            )
         } else if (localStorage.getItem("exam") !== null) {
            var i = JSON.parse(localStorage.getItem("exam"))
            this.setState(
               {
                  ...i,
               },
               () => {
                  this.startTimer()
               }
            )
            if (i.totalSecondRemaining === 0) history.push("/")
         } else history.push("/")
      } else {
         var result = await continueExam(this.examId)

         if (!result.isSuccess) history.push("/")
         this.setState({
            questions: this.shuffleQNA(result.data.questions),
            totalSecondRemaining: result.data.totalSeconds,
            startTimmer: true,
            mode: 1,
            name: result.data.name,
            sections: result.data.sections,
         })
         this.startTimer()
      }
   }

   getQuestion() {
      const questions = this.state.questions

      if (questions.length === 0) return

      const index = this.state.selectedQuestionIndex

      const question = {
         text: questions[index].text,
         imageUrl: questions[index].imageUrl,
         answers: questions[index].answers.map((a) => {
            return {
               id: a.id,
               text: a.text,
               correct: a.isCorrect,
            }
         }),
         answer: questions[index].selectedAnswers ?? [],
         showAnswer: this.state.mode === 2,
         answerType: questions[index].answerType,
      }

      return (
         <>
            <QNA
               onSelectedAnswerChanged={this.onSelectedAnswerChanged}
               question={question}
            />
         </>
      )
   }

   async finishExam() {
      if (this.state.mode === 2) {
         var data = {
            name: this.state.name,
            sections: [],
         }

         this.state.questions.forEach((q) => {
            let s = q.questionSection

            let sec = data.sections.filter((sc) => sc.id === s.id)

            if (sec.length === 1) {
               data = {
                  ...data,
                  sections: data.sections.map((sc) => {
                     if (sc.id === s.id) {
                        return {
                           ...sc,
                           questions: [...sc.questions, q],
                        }
                     }

                     return sc
                  }),
               }
            } else {
               const newSection = {
                  ...s,
                  questions: [q],
               }

               data.sections.push(newSection)
            }
         })

         localStorage.removeItem("exam")

         history.push({
            pathname: getReportPath("demo"),
            results: data,
         })
      } else {
         const result = await finishExam(this.examId)

         if (result.isSuccess) {
            if (result.data?.isForeign) {
               history.push({
                  pathname: "/magic-exam-result",
                  results: result.data,
               })
            } else history.push(getReportPath(this.examId))
         }
      }
   }

   render() {
      return (
         <>
            <ExamNavBar
               isStarted={true}
               onFinishExam={async () => await this.finishExam()}
               remainingTime={this.getRemainingTime()}
               examName={this.state.name}
               questionsCount={this.state.questions.length}
            />
            <Container className='d-flex flex-column my-5 py-5'>
               <Title level={4} className='mb-5'>
                  {this.state.selectedQuestionIndex + 1} /{" "}
                  {this.state.questions.length} Question
               </Title>
               <div className='bg-light p-5 rounded'>{this.getQuestion()}</div>
            </Container>
            <div class='clear-div'></div>
            <div className='exam-footer-div bg-light fixed-bottom p-5'>
               <Container className='exam-footer-container'>
                  <Button
                     className='d-flex align-items-center'
                     size='large'
                     onClick={this.btnBackClicked}>
                     <ArrowLeftOutlined />
                     Previous
                  </Button>
                  <Button
                     className='d-flex align-items-center'
                     size='large'
                     type='primary'
                     onClick={
                        this.state.selectedQuestionIndex + 1 ==
                        this.state.questions.length
                           ? this.finishExam
                           : this.btnNextClicked
                     }>
                     {this.state.selectedQuestionIndex + 1 ==
                     this.state.questions.length
                        ? "Finish"
                        : "Next"}
                     <ArrowRightOutlined />
                  </Button>
               </Container>
            </div>
         </>
      )
   }

   btnNextClicked() {
      const index = this.state.selectedQuestionIndex

      if (index + 1 === this.state.questions.length) return

      this.setState({
         selectedQuestionIndex: index + 1,
      })
   }

   async onSelectedAnswerChanged(i) {
      var sa = []

      this.setState({
         question: this.state.questions.map((q, k) => {
            if (k === this.state.selectedQuestionIndex) {
               var a = parseInt(i)

               if (q.answerType === 1) {
                  q.selectedAnswers = [a]
               } else {
                  if (!q.selectedAnswers) q.selectedAnswers = []

                  if (q.selectedAnswers.includes(a)) {
                     q.selectedAnswers = q.selectedAnswers.filter(
                        (q) => q !== a
                     )
                  } else q.selectedAnswers.push(a)
               }

               sa = q.selectedAnswers
            }
            return q
         }),
      })

      if (this.state.mode === 2) return

      const respose = await submitQuestion(
         this.examId,
         this.state.questions[this.state.selectedQuestionIndex].id,
         sa
      )
   }

   btnBackClicked() {
      const index = this.state.selectedQuestionIndex

      if (index === 0) return

      this.setState({
         selectedQuestionIndex: index - 1,
      })
   }

   secondsToTime(secs) {
      let hours = Math.floor(secs / (60 * 60))

      let divisor_for_minutes = secs % (60 * 60)
      let minutes = Math.floor(divisor_for_minutes / 60)

      let divisor_for_seconds = divisor_for_minutes % 60
      let seconds = Math.ceil(divisor_for_seconds)

      let obj = {
         h: hours,
         m: minutes,
         s: seconds,
      }
      return obj
   }

   startTimer() {
      if (this.timer === 0 && this.state.totalSecondRemaining > 0) {
         this.timer = setInterval(this.countDown, 1000)
      }
   }

   countDown() {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.totalSecondRemaining - 1
      this.setState({
         timer: this.secondsToTime(seconds),
         totalSecondRemaining: seconds,
      })
      // Check if we're at zero.
      if (seconds === 0) {
         clearInterval(this.timer)
      }
   }

   getRemainingTime() {
      var t = {
         h: this.state.timer.h.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
         }),
         m: this.state.timer.m.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
         }),
         s: this.state.timer.s.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
         }),
      }

      return (
         <span className='remaining-time'>
            {t.h}:{t.m}:{t.s}
         </span>
      )
   }
}
