import React from "react"
import { Navbar, NavbarBrand } from "react-bootstrap"
import { Button, Avatar, Menu, Dropdown } from "antd"
import ColoredLogo from "../assets/LogoColored.png"
import { clearUserData } from "../controllers/Authorization"
import history from "../history"
import { studentRoutes } from "../helpers/Paths"

export default function MainNavbar(props) {
   const menu = (
      <Menu>
         <Menu.Item>
            <h4>{props.username}</h4>
         </Menu.Item>

         <Menu.Item>
            <Button
               type='link'
               onClick={() => {
                  history.push(studentRoutes.dashboard)
               }}>
               Dashboard
            </Button>
         </Menu.Item>
         <Menu.Item>
            <Button
               type='link'
               onClick={() => {
                  history.push(studentRoutes.profile)
               }}>
               Profile
            </Button>
         </Menu.Item>
         <Menu.Item>
            <Button
               type='link'
               onClick={() => {
                  history.push(studentRoutes.results)
               }}>
               Results
            </Button>
         </Menu.Item>
         <Menu.Item danger>
            <Button
               type='link'
               onClick={() => {
                  clearUserData()
                  history.push("/student-login")
               }}>
               Logout
            </Button>
         </Menu.Item>
      </Menu>
   )
   return (
      <Navbar className='p-0' style={{ backgroundColor: "#FFF" }} sticky='top'>
         <NavbarBrand>
            <img
               onClick={() => {
                  history.push(studentRoutes.dashboard)
               }}
               className='cursor-pointer'
               alt='logo'
               src={ColoredLogo}
               width='150'
            />
         </NavbarBrand>
         <Button
            className='mr-auto'
            onClick={() => {
               history.push(studentRoutes.dashboard)
            }}
            size='large'>
            Browse Exams
         </Button>
         <Dropdown overlay={menu}>
            <Avatar
               className='px-5'
               style={{ backgroundColor: "#764D9C" }}
               size='large'
               shape='square'>
               {props.username.charAt(0).toUpperCase()}
            </Avatar>
         </Dropdown>
      </Navbar>
   )
}
