import { Store } from "react-notifications-component"
import { message } from "antd"
export function showWarningNotification(m, t = "Warning") {
   notify(m, t, "warning")
}

export function showErrorNotification(m, t = "Error") {
   notify(m, t, "danger")
}

export function showSuccessNotification(m, t = "Message") {
   notify(m, t, "success")
}

export function notify(m, t, tp) {
   Store.addNotification({
      title: t,
      message: m,
      type: tp,
      insert: "bottom",
      container: "top-left",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
         duration: 5000,
         onScreen: true,
      },
   })
}
