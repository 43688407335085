import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Button } from "antd"
import history from "../history"
import React, { Component } from "react"
import { adminPaths } from "./Paths"

export const examViewColumns = [
   {
      title: "Id",
      dataIndex: "id",
      key: "id",
   },
   {
      title: "Name",
      dataIndex: "name",
      key: "name",
   },
   {
      title: "Duration",
      dataIndex: "durationInSec",
      key: "durationInSec",
      render: (text, row) => <span>{row.durationInSec / 60} mins</span>,
   },
   {
      title: "Total Sections",
      dataIndex: "sections",
      key: "sections",
      render: (text, row) => <span>{row.sections.length}</span>,
   },
   {
      title: "Total Questions",
      dataIndex: "questions",
      key: "question",
      render: (text, row) => <span>{row.questions.length}</span>,
   },
   {
      title: "Demo",
      dataIndex: "allowDemo",
      key: "allowDemo",
      render: (text, row) => (
         <span>{row.allowDemo ? <CheckOutlined /> : <CloseOutlined />}</span>
      ),
   },
   {
      title: "Detail",
      dataIndex: "view",
      key: "view",
      render: (text, row) => (
         <>
            <Button
               type='link'
               onClick={() =>
                  history.push({
                     pathname: adminPaths.examManagement + "/exam/" + row.id,
                     row,
                  })
               }>
               View
            </Button>
         </>
      ),
   },
]
