import React, { Component } from "react"
import "./custom.css"
import LoginView from "./components/LoginView"
import { Redirect, Route, Switch } from "react-router-dom"
import Exam from "./components/Exam"
import Dashboard from "./components/Dashboard"
import UnAuthorizedRoute from "./helpers/UnAuthorizedRoute"
import AuthorizedRoute from "./helpers/AuthorizedRoute"
import Report from "./components/Report"
import Instructions from "./components/TestInformation/Instructions"
import { adminPaths, studentRoutes } from "./helpers/Paths"
import AdminLogin from "./components/admin/AdminLogin"
import AdminDashboard from "./components/admin/AdminDashboard"
import "react-notifications-component/dist/theme.css"
import { ReactNotifications } from "react-notifications-component"
import "./styles/css/antd.css"
import ProfileManagement from "./components/student/ProfileManagement"
import StudentResults from "./components/student/StudentResults"
import MagicLinkEndPoint from "./components/foreignstudent/MagicLinkEndPoint"
import MagicLinkExamResult from "./components/foreignstudent/MagicLinkExamResult"

export default class App extends Component {
   static displayName = App.name

   render() {
      return (
         <>
            <ReactNotifications />
            <Switch>
               <Route
                  path={"/magic-exam/:id?"}
                  exact
                  render={(p) => <MagicLinkEndPoint {...p} />}
               />
               <Route
                  path={"/magic-exam-result"}
                  exact
                  render={(p) => <MagicLinkExamResult {...p} />}
               />

               <UnAuthorizedRoute
                  exact
                  path={studentRoutes.login}
                  component={LoginView}
               />
               <AuthorizedRoute
                  exact
                  path={studentRoutes.results}
                  component={StudentResults}
               />
               <AuthorizedRoute
                  exact
                  path={studentRoutes.profile}
                  component={ProfileManagement}
               />
               <AuthorizedRoute
                  exact
                  path={studentRoutes.dashboard}
                  component={Dashboard}
               />
               <AuthorizedRoute
                  exact
                  path={studentRoutes.instructions}
                  component={Instructions}
               />
               <AuthorizedRoute
                  exact
                  path={studentRoutes.report}
                  component={Report}
               />
               <AuthorizedRoute
                  exact
                  path={studentRoutes.exam}
                  component={Exam}
               />
               <UnAuthorizedRoute
                  exact
                  type={2}
                  path={adminPaths.login}
                  component={AdminLogin}
               />

               <AuthorizedRoute
                  path={"/admin/"}
                  component={AdminDashboard}
                  type={2}
               />

               <Redirect to={studentRoutes.login} />
            </Switch>
         </>
      )
   }
}
